<template>
  <Transition name="fade">
    <div
      class="fixed top-0 left-0 z-[70] bg-[#A3A3A3]/20 backdrop-blur-sm w-full h-screen py-10 overflow-y-scroll no-scrollbar flex justify-center"
    >
      <form
        @submit.prevent="handleSchedule"
        class="self-start bg-white w-full max-w-[550px] p-6 md:p-12 rounded-[38px] flex flex-col gap-y-5 relative"
      >
        <div
          class="absolute rounded-[44px] left-0 top-0 w-full h-full"
          @click="closeDropdowns"
        ></div>

        <div class="flex justify-between items-center">
          <h3 class="text-GunMetal text-xl font-bold">
            {{ appointmentValues?.type === "default" ? "Request" : "Update" }}
            Appointment
          </h3>
          <button type="button" class="self-start" @click="cancelAppointment">
            <span
              class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 befoafterre:top-0 after:-rotate-45 cursor-pointer"
            ></span>
          </button>
        </div>

        <div>
          <h4 class="text-DarkJungle text-xs font-semibold mb-3">
            Appointment Type
          </h4>

          <div class="bg-WhiteLilac w-fit p-1 relative">
            <button
              type="button"
              class="w-[126px] text-sm py-1"
              :class="
                currentTab === 'Physical'
                  ? 'bg-ResolutionBlue text-white font-bold'
                  : 'text-PaleSky'
              "
              @click="currentTab = 'Physical'"
            >
              Physical
            </button>
            <button
              type="button"
              class="w-[126px] text-sm py-1"
              :class="
                currentTab === 'Online'
                  ? 'bg-ResolutionBlue text-white font-bold'
                  : 'text-PaleSky'
              "
              @click="currentTab = 'Online'"
            >
              Online
            </button>
          </div>
        </div>

        <div v-if="currentTab === 'Physical'" class="flex flex-col gap-y-4">
          <div class="relative">
            <label for="country" class="text-BluishGrey text-sm">Country</label>
            <input
              type="text"
              id="country"
              class="w-full border-b border-BlueChalk outline-none focus:border-ResolutionBlue focus:ring-2"
              v-model="country"
              @focus="openCountriesDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openCountriesDropdown"
            />
            <LocationsDropdown
              v-show="showCountriesDropdown"
              :locations="countries"
              @setLocation="selectCountry"
            />
          </div>

          <div class="relative">
            <label for="state" class="text-BluishGrey text-sm">State</label>
            <input
              type="text"
              id="state"
              class="w-full border-b border-BlueChalk outline-none focus:border-ResolutionBlue focus:ring-2"
              v-model="state"
              @focus="openStatesDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openStatesDropdown"
            />
            <LocationsDropdown
              v-show="showStatesDropdown"
              :locations="states"
              @setLocation="selectState"
            />
          </div>

          <div class="relative">
            <label for="city" class="text-BluishGrey text-sm">City</label>
            <input
              type="text"
              id="city"
              class="w-full border-b border-BlueChalk outline-none focus:border-ResolutionBlue focus:ring-2"
              v-model="city"
              @focus="openCitiesDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openCitiesDropdown"
            />
            <LocationsDropdown
              v-show="showCitiesDropdown"
              :locations="cities"
              @setLocation="selectCity"
            />
          </div>

          <div class="relative">
            <label for="health-center" class="text-BluishGrey text-sm"
              >Health Center</label
            >
            <input
              type="text"
              id="health-center"
              class="w-full border-b border-BlueChalk outline-none focus:border-ResolutionBlue focus:ring-2"
              v-model="center"
              @focus="openCentersDropdown"
            />
            <img
              src="../../../../assets/icons/down_caret.svg"
              alt="down caret icon"
              class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              @click="openCentersDropdown"
            />
            <LocationsDropdown
              v-show="showHealthCentersDropdown"
              :locations="centers"
              @setLocation="selectCenter"
            />
          </div>

          <div class="relative">
            <label for="appointmentReason" class="text-BluishGrey text-sm"
              >Reason for appointment</label
            >
            <template v-if="isOtherReason">
              <input
                type="text"
                class="w-full border-b border-BlueChalk outline-none focus:ring-ResolutionBlue"
                v-model="customReason"
                placeholder="Please specify your reason"
              />
            </template>
            <template v-else>
              <div
                class="w-full border-b border-BlueChalk py-2 flex justify-between items-center cursor-pointer"
                @click="toggleDropdown"
              >
                <span
                  :class="
                    appointmentReason
                      ? 'text-DarkJungleGreen'
                      : 'text-BluishGrey'
                  "
                >
                  {{ appointmentReason || "Select reason" }}
                </span>
                <img
                  src="../../../../assets/icons/down_caret.svg"
                  alt="down caret icon"
                  class="h-4 w-4"
                />
              </div>
              <!-- Dropdown for reasons -->
              <div
                v-if="showReasonsDropdown"
                class="absolute z-10 w-full bg-white border border-BlueChalk mt-1"
              >
                <div
                  v-for="(reason, index) in reasons"
                  :key="index"
                  @click="selectReason(reason)"
                  class="p-2 hover:bg-gray-100 cursor-pointer"
                >
                  {{ reason.label }}
                </div>
              </div>
            </template>
          </div>

          <div
            class="relative flex justify-between items-center text-BluishGrey text-sm"
          >
            <div class="flex flex-col gap-2">
              <label for="date">Date</label>
              <div class="min-w-[120px] relative">
                <input
                  type="date"
                  id="date"
                  class="custom-input w-full border-BlueChalk border-b outline-none focus:border-ResolutionBlue focus:ring-2 relative"
                  :min="currentDate"
                  v-model="date"
                />
                <button
                  type="button"
                  class="size-6 absolute top-1/2 right-0 -translate-y-1/2 z-[1]"
                >
                  <img :src="calendarIcon" alt="calendar icon" />
                </button>
              </div>
            </div>

            <div>
              <div class="flex flex-col gap-2">
                <label for="time">Time</label>
                <select
                  name="time"
                  id="time"
                  class="outline-BlueChalk border-b border-BlueChalk"
                  v-model="time"
                >
                  <option value="">Select Time</option>
                  <option
                    v-for="(item, index) in availableTimes"
                    :key="index"
                    :value="item.value"
                    class="uppercase"
                  >
                    {{ item.time }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="h-full flex justify-center items-center">
          <p>Coming soon</p>
        </div>

        <div
          v-if="address && currentTab === 'Physical'"
          class="bg-Magnolia py-3 pr-12 pl-4 rounded-[10px]"
        >
          <div class="flex gap-1.5">
            <div>
              <img
                src="../../../../assets/icons/location_pin.svg"
                alt="location pin icon"
              />
            </div>
            <div class="text-ResolutionBlue">
              <p class="text-xs mb-0.5">
                This health center is located at the following address:
              </p>
              <p class="text-sm font-bold">
                {{ address }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="currentTab === 'Physical'"
          class="mt-3 flex justify-center items-center gap-2 flex-wrap relative"
          :class="currentTab === 'Online' && 'mt-4'"
        >
          <button
            type="button"
            class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
            @click="cancelAppointment"
          >
            Cancel
          </button>
          <button
            v-if="currentTab === 'Physical'"
            type="submit"
            class="text-sm font-semibold rounded-[44px] py-[13px] px-[52px]"
            :class="
              allSelected
                ? 'bg-ResolutionBlue text-white'
                : 'bg-Geyser text-MistBlue'
            "
          >
            <span v-if="isPending" class="pl-4">
              <LoadingSpinner />
            </span>
            <span v-else>
              {{ appointmentValues?.type === "default" ? "Book" : "Update" }}
            </span>
          </button>
          <router-link
            to="/patient/online-appointment"
            v-if="currentTab === 'Online'"
            class="cursor-pointer w-fit text-sm font-semibold rounded-[44px] py-[13px] px-[52px] bg-ResolutionBlue text-white"
            @click="openCalendly"
          >
            Schedule
          </router-link>
        </div>
      </form>
    </div>
  </Transition>
</template>

<script>
import { ref, inject, computed, watch } from "vue";
import { push } from "notivue";
import { useMutation, useQueryClient, useQuery } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { extractTime } from "@/utils/timeFormatter";
import LocationsDropdown from "../dropdowns/LocationsDropdown.vue";
import ReasonsForAppointmentDropdown from "./ReasonsForAppointmentDropdown.vue";
import calendarIcon from "@/assets/icons/Calendar.svg";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

export default {
  name: "AppointmentModal",
  components: {
    LocationsDropdown,
    ReasonsForAppointmentDropdown,
  },
  props: {
    appointmentValues: Object, // Data passed from the modal wrapper
  },
  setup(props) {
    const closeAppointmentModal = inject("closeAppointmentModal");
    const setAppointmentModalVal = inject("setAppointmentModalVal");

    const queryClient = useQueryClient();

    const {
      "appointment/bookAppointment": bookAppointment,
      "appointment/editAppointment": editAppointment,
      "appointment/fetchPatientAppointments": fetchPatientAppointments,
      "appointment/getAvailableTimes": getAvailableTimes,
      "test/fetchAllHealthCenters": fetchAllHealthCenters,
    } = mapActions();

    const showCountriesDropdown = ref(false);
    const showStatesDropdown = ref(false);
    const showCitiesDropdown = ref(false);
    const showHealthCentersDropdown = ref(false);
    const showReasonsDropdown = ref(false);
    const selectedReason = ref("");
    const customReason = ref("");
    const showInputField = ref(false);
    const currentTab = ref("Physical");
    const posts = ref([]);
    const allData = ref([]);
    const countries = ref([]);
    const states = ref([]);
    const cities = ref([]);
    const centers = ref([]);
    const processing = ref(false);
    const reasons = [
      { label: "Free Testing", value: "Free Testing" },
      {
        label: "Free Testing and Consultation",
        value: "Free Testing and Consultation",
      },
      { label: "IT Issues", value: "IT Issues" },
      {
        label: "Registration and Onboarding",
        value: "Registration and Onboarding",
      },
      { label: "General Consultation", value: "General Consultation" },
      { label: "Others", value: "" },
    ];

    const setValues = (data) => {
      allData.value = data;
      data.forEach((post) => {
        countries.value.push(post.country);
        states.value.push(post.state);
        cities.value.push(post.city);
        centers.value.push(post.name);
      });
      countries.value = [...new Set(countries.value)];
      states.value = [...new Set(states.value)];
      cities.value = [...new Set(cities.value)];
      centers.value = [...new Set(centers.value)];
    };

    // Prefill form fields if editing, otherwise empty for new appointment
    const country = ref(props.appointmentValues?.country || "");
    const state = ref(props.appointmentValues?.state || "");
    const city = ref(props.appointmentValues?.city || "");
    const center = ref(props.appointmentValues?.center || "");
    const isOtherReason = ref(false);
    const appointmentReason = ref(
      props.appointmentValues?.appointmentReason || ""
    );
    const address = ref("");
    const centerId = ref(null);
    const date = ref(props.appointmentValues?.date || "");
    const time = ref(props.appointmentValues?.time || "");
    const availableTimes = ref([]);
    const appointmentType = ref("default");

    // Toggle dropdown visibility
    const toggleDropdown = () => {
      showReasonsDropdown.value = !showReasonsDropdown.value;
    };

    // Method to handle reason selection
    const selectReason = (reason) => {
      if (reason.value === "") {
        isOtherReason.value = true; // Show the input field for custom reason
        appointmentReason.value = "";
      } else {
        isOtherReason.value = false; // Hide the input field for custom reason
        appointmentReason.value = reason.label; // Set the selected reason
      }
      showReasonsDropdown.value = false; // Close the dropdown after selection
    };

    // Close input field when focus is lost
    const closeInputField = () => {
      if (customReason.value) {
        selectedReason.value = customReason.value;
      }
      showInputField.value = false;
    };

    watch([centerId, date], ([newCenterId, newDate]) => {
      if (newCenterId && newDate) {
        fetchAvailableTimes(newCenterId, newDate);
      }
    });

    const fetchAvailableTimes = async (healthCentreId, date) => {
      try {
        const times = await getAvailableTimes({ healthCentreId, date });
        availableTimes.value = times.map((slot) => ({
          time: slot.time,
          value: slot.time,
        }));
      } catch (error) {
        push.error(error.response.data.message);
      }
    };

    const currentDate = ref(new Date().toISOString().slice(0, 10));

    const fetchHealthCenters = async () => {
      try {
        const res = await fetchAllHealthCenters();
        posts.value = res.posts;
        setValues(res.posts);
      } catch (error) {
        console.log("Error fetching all health centers", error);
      }
    };

    fetchHealthCenters();

    const allSelected = computed(() => {
      return !!(
        country.value &&
        state.value &&
        city.value &&
        center.value &&
        date.value &&
        time.value &&
        (isOtherReason.value ? customReason.value : appointmentReason.value)
      );
    });

    const selectCountry = (value) => {
      country.value = value;
      showCountriesDropdown.value = false;
      const c = allData.value.filter(
        (datum) => datum.country === country.value
      );
      states.value = [...new Set(c.map((item) => item.state))];
    };

    const selectState = (value) => {
      state.value = value;
      showStatesDropdown.value = false;
      const st = allData.value.filter((datum) => datum.state === state.value);
      cities.value = [...new Set(st.map((item) => item.city))];
    };

    const selectCity = (value) => {
      city.value = value;
      showCitiesDropdown.value = false;
      const cty = allData.value.filter((datum) => datum.city === city.value);
      centers.value = [
        ...new Set(cty.map((item) => ({ id: item.id, name: item.name }))),
      ];
    };

    const selectCenter = (value) => {
      center.value = value.name;
      centerId.value = value.id;
      showHealthCentersDropdown.value = false;

      const location = allData.value.find((item) => item.name === value.name);
      if (location) {
        address.value = location.address;
      }

      // Fetch available times for the selected center if date is set
      if (date.value) {
        fetchAvailableTimes(value.id, date.value);
      }
    };

    const openCountriesDropdown = () => {
      showCountriesDropdown.value = true;
      showStatesDropdown.value = false;
      showCitiesDropdown.value = false;
      showHealthCentersDropdown.value = false;
    };

    const openStatesDropdown = () => {
      showStatesDropdown.value = true;
      showCountriesDropdown.value = false;
      showCitiesDropdown.value = false;
      showHealthCentersDropdown.value = false;
    };

    const openCitiesDropdown = () => {
      showCitiesDropdown.value = true;
      showStatesDropdown.value = false;
      showCountriesDropdown.value = false;
      showHealthCentersDropdown.value = false;
    };

    const openCentersDropdown = () => {
      showHealthCentersDropdown.value = true;
      showCitiesDropdown.value = false;
      showStatesDropdown.value = false;
      showCountriesDropdown.value = false;
    };

    const closeDropdowns = () => {
      showCountriesDropdown.value = false;
      showStatesDropdown.value = false;
      showCitiesDropdown.value = false;
      showHealthCentersDropdown.value = false;
      showReasonsDropdown.value = false;
    };

    const cancelAppointment = () => {
      resetInputFields();
      setValues(posts.value);
      setAppointmentModalVal({
        type: "default",
        country: "",
        state: "",
        city: "",
        center: "",
        appointmentReason: "",
        address: "",
        date: "",
        time: "",
        appointmentId: "",
      });
      closeDropdowns();
      closeAppointmentModal();
    };

    // Vue Query mutation for booking an appointment using the Vuex action
    const { isPending: isBookingPending, mutate: bookMutate } = useMutation({
      mutationFn: (payload) => bookAppointment(payload),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["appointments"] });
        push.success("Appointment booked successfully");
        resetInputFields();
        closeAppointmentModal();
      },
      onError: (error) => {
        const errorMsg =
          error?.response?.data?.message || "Error booking appointment";
        push.error(errorMsg);
      },
    });

    const { isPending: isEditPending, mutate: editMutate } = useMutation({
      mutationFn: (payload) => editAppointment(payload),

      onSuccess:  (data) => {

        // Invalidate queries to fetch the updated data
        queryClient.invalidateQueries({ queryKey: ["edit-appointment"] });

        push.success("Appointment edited successfully");

        // Reset fields and close modal after successful update
        resetInputFields();
        closeAppointmentModal();
      },

      onError: (error) => {
        // Check if the error is valid and has a message, or use a default error message
        const errorMsg =
          error?.response?.data?.message || "Error editing appointment.";

        push.error(errorMsg);
      },
    });

    const handleSchedule = () => {
      const data = {
        type: currentTab.value.toLowerCase(),
        reason_for_appointment: isOtherReason.value
          ? customReason.value
          : appointmentReason.value,
        date: date.value,
        // Format time to HH:MM:SS
        time: time.value.length === 5 ? `${time.value}:00` : time.value,
      };

      const payload = {
        id: centerId.value,
        data,
      };

      const editPayload = {
        appointmentId: props.appointmentValues.appointmentId,
        data,
      };

      if (allSelected.value) {
        if (props.appointmentValues.type == "default") {
          bookMutate(payload);
        } else {
          editMutate(editPayload);
        }
      } else {
        push.info("Fill in all fields");
      }
    };

    const openCalendly = () => {
      closeAppointmentModal();
      const calendlyLink = "https://calendly.com/turbomedics/30min";

      // Check if Calendly is already initialized
      if (typeof Calendly !== "undefined") {
        Calendly.initPopupWidget({ url: calendlyLink });
      } else {
        // Load Calendly script if not already loaded
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        script.onload = () => {
          Calendly.initPopupWidget({ url: calendlyLink });
        };
        document.head.appendChild(script);
      }
    };

    const resetInputFields = () => {
      country.value = "";
      state.value = "";
      city.value = "";
      center.value = "";
      appointmentReason.value = "";
      currentTab.value = "Physical";
      address.value = "";
      date.value = "";
      time.value = "";
      customReason.value = "";
    };
    console.log("Appointment Values: ", props.appointmentValues);

    watch(
      () => props.appointmentValues,
      (newValues) => {
        if (newValues) {
          country.value = newValues.country || "";
          state.value = newValues.state || "";
          city.value = newValues.city || "";
          center.value = newValues.center || "";
          appointmentReason.value = newValues.appointmentReason || "";
          address.value = newValues.address || "";
          date.value = newValues.date || "";
          time.value = newValues.time || "";

          // If the center is prefilled, set the centerId and fetch available times
          if (newValues.center) {
            const selectedCenter = allData.value.find(
              (item) => item.name === newValues.center
            );
            if (selectedCenter) {
              centerId.value = selectedCenter.id; // Prefill the center ID
              fetchAvailableTimes(selectedCenter.id, newValues.date); // Fetch available times if date is also prefilled
            }
          }
        }
      },
      { immediate: true }
    );

    return {
      calendarIcon,
      closeAppointmentModal,
      country,
      countries,
      selectCountry,
      showCountriesDropdown,
      openCountriesDropdown,
      state,
      states,
      selectState,
      showStatesDropdown,
      openStatesDropdown,
      city,
      cities,
      selectCity,
      showCitiesDropdown,
      openCitiesDropdown,
      center,
      centers,
      selectCenter,
      showHealthCentersDropdown,
      openCentersDropdown,
      address,
      appointmentReason,
      currentTab,
      cancelAppointment,
      closeDropdowns,
      allSelected,
      handleSchedule,
      openCalendly,
      date,
      time,
      availableTimes,
      currentDate,
      processing,
      appointmentType,
      showReasonsDropdown,
      isOtherReason,
      toggleDropdown,
      reasons,
      selectReason,
      closeInputField,
      customReason,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, scale 0.3s ease;
  scale: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  scale: 1.2;
}
</style>
