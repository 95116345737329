<template>
  <div class="w-full h-screen flex justify-center items-center px-4">
    <div>
      <div class="text-center">
        <router-link to="/">
          <img
            src="../../../assets/icons/turbomedic-logo__.svg"
            alt="logo"
            class="w-32 md:w-40 mx-auto"
          />
        </router-link>
        <p class="text-ResolutionBlue mt-2">Your health directed by YOU</p>
      </div>

      <div class="text-center mt-20">
        <div v-if="email" class="mb-8">
          <div>
            <h1 class="text-black text-2xl font-bold mb-2">
              Verify your email
            </h1>
            <p class="text-OlsoGrey text-base max-w-[305px] mx-auto">
              We’ve sent an email to {{ email }}. Click on the button in the
              mail to verify your email address. If it’s not in your inbox, it
              might be in your spam or junk folder.
            </p>
            <p class="text-OlsoGrey text-sm mb-8 mt-4">
              Can’t find the email?
              <span
                class="text-ResolutionBlue font-bold cursor-pointer"
                @click="resendEmail"
                >Resend Email</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { push } from "notivue";
import { mapActions } from "@/hooks/mapStore";

export default {
  name: "VerifyEmail",
  setup() {
    const route = useRoute();
    const email = ref("");

    if (typeof route.query.email === "string") {
      email.value = route.query.email.replace("%", "@");
    } else {
      email.value = "";
    }

    const { "auth/resendVerificationEmail": resendVerificationEmail } =
      mapActions();

    const resendEmail = async () => {
      try {
        await resendVerificationEmail(email.value);
        push.success("Verification email resent successfully.");
      } catch (error) {
        if (error.message === "Network Error") {
          push.error("Network Error. Please try again later.");
        } else {
          push.error("Failed to resend verification email. Please try again.");
        }
      }
    };

    return {
      email,
      resendEmail,
    };
  },
};
</script>
