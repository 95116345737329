<template>
  <div class="w-full md:w-[70%]">
    <!-- Basic Information Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openBasiInfo !== true,
        'mb-12': openBasiInfo === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openBasiInfo = !openBasiInfo"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">Basic</h4>
        <div>
          <span>
            <img
              src="../../../../assets/icons/arrow-up-iocn.svg"
              :class="{
                'rotate-180': openBasiInfo !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openBasiInfo === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <SelectField
            label="Title"
            v-model="title"
            :options="nameTitles"
            :placeholder="getPatientProfile?.person?.title || '--'"
          />
          <InputField
            label="First name"
            v-model="firstName"
            :placeholder="getPatientProfile?.person?.first_name || '--'"
          />
          <InputField
            label="Last Name"
            v-model="lastName"
            :placeholder="getPatientProfile?.person?.last_name || '--'"
          />
          <div
            class="cursor-not-allowed bg-WhiteLilac flex flex-col w-full pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">User name</p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{
                getPatientProfile?.person?.username
                  ? getPatientProfile?.person?.username
                  : "--"
              }}
            </p>
          </div>
          <InputField
            label="Date of Birth"
            v-model="dob"
            type="date"
            :placeholder="getPatientProfile?.person?.date_of_birth || '--'"
            :max="currentDate"
          />
          <div
            class="cursor-not-allowed flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Age</p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{ getPatientProfile?.person?.age }}
            </p>
          </div>
          <SelectField
            label="Gender"
            v-model="gender"
            :placeholder="getPatientProfile?.person?.gender"
            :options="['Male', 'Female']"
          />
          <SelectField
            label="Marital Status"
            v-model="maritalStatus"
            :placeholder="getPatientProfile?.person?.marital_status"
            :options="['Single', 'Married', 'Divorced']"
          />
        </div>
      </div>
    </div>

    <!-- Residential Address Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openResidentialAddress !== true,
        'mb-12': openResidentialAddress === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openResidentialAddress = !openResidentialAddress"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Residential Address 1
        </h4>
        <div>
          <span>
            <img
              src="../../../../assets/icons/arrow-up-iocn.svg"
              :class="{
                'rotate-180': openResidentialAddress !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openResidentialAddress === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            class="cursor-not-allowed flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Address</p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{
                getPatientProfile?.person?.address
                  ? getPatientProfile?.person?.address
                  : "--"
              }}
            </p>
          </div>
          <SelectField
            label="Country"
            v-model="country"
            :options="countries"
            :placeholder="getPatientProfile?.person?.country"
          />
          <SelectField
            label="State"
            v-model="state"
            :options="states"
            :placeholder="getPatientProfile?.person?.state"
          />
          <InputField
            label="City"
            v-model="city"
            :placeholder="getPatientProfile?.person?.city"
          />
          <InputField
            label="Zip Code"
            v-model="zipCode"
            :placeholder="getPatientProfile?.person?.zip_code"
          />
        </div>
      </div>
    </div>

    <!-- Contact Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openContact !== true,
        'mb-12': openContact === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openContact = !openContact"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">Contact</h4>
        <div>
          <span>
            <img
              src="../../../../assets/icons/arrow-up-iocn.svg"
              :class="{
                'rotate-180': openContact !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openContact === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            label="Phone Number (Primary)"
            type="number"
            v-model="phone"
            :placeholder="getPatientProfile?.person?.phone"
          />
          <InputField
            label="Phone Number (Secondary)"
            type="number"
            v-model="secondaryPhone"
            :placeholder="getPatientProfile?.person?.secondary_phone"
          />
          <div
            class="cursor-not-allowed flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
          >
            <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
              Email (Primary)
            </p>
            <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
              {{
                getPatientProfile?.person?.email
                  ? getPatientProfile?.person?.email
                  : "--"
              }}
            </p>
          </div>
          <InputField
            label="Email (Secondary)"
            v-model="secondaryEmail"
            type="email"
            :placeholder="getPatientProfile?.person?.secondary_email"
          />
        </div>
      </div>
    </div>

    <!-- Preferred Language Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openSpokenLanguage !== true,
        'mb-12': openSpokenLanguage === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openSpokenLanguage = !openSpokenLanguage"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Preferred language of communication
        </h4>
        <div>
          <span>
            <img
              src="../../../../assets/icons/arrow-up-iocn.svg"
              :class="{
                'rotate-180': openSpokenLanguage !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openSpokenLanguage === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <SelectField
            label="Language"
            v-model="language"
            :options="languages"
            :placeholder="getPatientProfile?.person?.language"
          />
          <SelectField
            label="Language Proficiency"
            v-model="languageProficiency"
            :options="proficiencyLevels"
            :placeholder="getPatientProfile?.person?.language_proficiency"
          />
          <SelectField
            label="Language 2 (optional)"
            v-model="secondLanguage"
            :options="languages"
            :placeholder="getPatientProfile?.person?.second_language"
          />
          <SelectField
            label="Language 2 Proficiency"
            v-model="secondLanguageProficiency"
            :options="proficiencyLevels"
            :placeholder="
              getPatientProfile?.person?.second_language_proficiency
            "
          />
          <SelectField
            label="Language 3 (optional)"
            v-model="thirdLanguage"
            :options="languages"
            :placeholder="getPatientProfile?.person?.third_language"
          />
          <SelectField
            label="Language 3 Proficiency"
            v-model="thirdLanguageProficiency"
            :options="proficiencyLevels"
            :placeholder="getPatientProfile?.person?.third_language_proficiency"
          />
        </div>
      </div>
    </div>

    <!-- Emergency Contact Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openEmergencyContact !== true,
        'mb-12': openEmergencyContact === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openEmergencyContact = !openEmergencyContact"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Emergency Contact (1)
        </h4>
        <div>
          <span>
            <img
              src="../../../../assets/icons/arrow-up-iocn.svg"
              :class="{
                'rotate-180': openEmergencyContact !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openEmergencyContact === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            label="Name"
            v-model="emergencyContactName"
            :placeholder="getPatientProfile?.person?.emergency_contact_name"
          />
          <template v-if="emergencyContactRelationship === 'Other'">
            <InputField
              label="Relationship"
              v-model="emergencyContactRelationshipOther"
              placeholder="Please specify"
            />
          </template>
          <template v-else>
            <SelectField
              label="Relationship"
              v-model="emergencyContactRelationship"
              :options="[
                'Brother',
                'Sister',
                'Mother',
                'Father',
                'Neighbour',
                'Friend',
                'Husband',
                'Wife',
                'Other',
              ]"
              :placeholder="
                getPatientProfile?.person?.emergency_contact_relationship
              "
            />
          </template>
          <InputField
            label="Phone Number (Primary)"
            type="number"
            v-model="emergencyContactPhone"
            :placeholder="getPatientProfile?.person?.emergency_contact_phone"
          />
          <InputField
            label="Phone Number (Secondary)"
            type="number"
            v-model="emergencyContactPhoneSecondary"
            :placeholder="
              getPatientProfile?.person?.emergency_contact_phone_secondary
            "
          />
          <SelectField
            label="Language of Communication"
            v-model="emergencyContactLanguage"
            :options="languages"
            :placeholder="getPatientProfile?.person?.emergency_contact_language"
          />
        </div>
      </div>
    </div>

    <!-- Second Emergency Contact Section -->
    <div
      class="space-y-2.5"
      :class="{
        'border-b border-b-BlueChalk mb-4': openEmergencyContact_2 !== true,
        'mb-12': openEmergencyContact_2 === true,
        'transition-transform duration-700': true,
      }"
    >
      <div
        class="flex items-center justify-between space-x-4 cursor-pointer"
        @click="openEmergencyContact_2 = !openEmergencyContact_2"
      >
        <h4 class="text-MistBlue font-bold text-xs md:text-sm">
          Emergency Contact (2)
        </h4>
        <div>
          <span>
            <img
              src="../../../../assets/icons/arrow-up-iocn.svg"
              :class="{
                'rotate-180': openEmergencyContact_2 !== true,
                'transition-transform': true,
                'duration-700': true,
              }"
              alt=""
            />
          </span>
        </div>
      </div>
      <div
        :class="[
          {
            'max-h-0': true,
            'overflow-hidden': true,
            'transition-max-height': true,
            'duration-1000': true,
            'max-h-[500px]': openEmergencyContact_2 === true,
          },
        ]"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            label="Name"
            v-model="secondEmergencyContactName"
            :placeholder="
              getPatientProfile?.person?.second_emergency_contact_name
            "
          />
          <template v-if="secondEmergencyContactRelationship === 'Other'">
            <InputField
              label="Relationship"
              v-model="secondEmergencyContactRelationshipOther"
              placeholder="Please specify"
            />
          </template>
          <template v-else>
            <SelectField
              label="Relationship"
              v-model="secondEmergencyContactRelationship"
              :options="[
                'Brother',
                'Sister',
                'Mother',
                'Father',
                'Neighbour',
                'Friend',
                'Husband',
                'Wife',
                'Other',
              ]"
              :placeholder="
                getPatientProfile?.person?.second_emergency_contact_relationship
              "
            />
          </template>
          <InputField
            label="Phone Number (Primary)"
            type="number"
            v-model="secondEmergencyContactPhone"
            :placeholder="
              getPatientProfile?.person?.second_emergency_contact_phone
            "
          />
          <InputField
            label="Phone Number (Secondary)"
            type="number"
            v-model="secondEmergencyContactPhoneSecondary"
            :placeholder="
              getPatientProfile?.person
                ?.second_emergency_contact_phone_secondary
            "
          />
          <SelectField
            label="Language of Communication"
            v-model="secondEmergencyContactLanguage"
            :placeholder="
              getPatientProfile?.person?.second_emergency_contact_language
            "
            :options="languages"
          />
        </div>
      </div>
    </div>

    <div class="update-profile-btn-wrapper">
      <button
        @click="updatePersonalInfo"
        type="submit"
        class="w-48 px-8 py-3 bg-ResolutionBlue text-white rounded-3xl hover:bg-DodgerBlue hover:text-white transition-all duration-300"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else>Update profile</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { push } from "notivue";
import { states } from "@/utils/mockData/states";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import InputField from "../../ui/InputField.vue";
import SelectField from "../../ui/SelectField.vue";

const {
  "user/updateUserPersonalInfo": updateUserPersonalInfo,
  "user/fetchPatientProfile": fetchPatientProfile,
} = mapActions();
const { "user/getPatientProfile": getPatientProfile } = mapGetters();

const openBasiInfo = ref(true);
const openResidentialAddress = ref(true);
const openContact = ref(true);
const openSpokenLanguage = ref(true);
const openEmergencyContact = ref(true);
const openEmergencyContact_2 = ref(true);

const isLoading = ref(false);

const title = ref("");
const firstName = ref("");
const lastName = ref("");
const dob = ref("");
const gender = ref("");
const maritalStatus = ref("");
const country = ref("");
const countries = ref([]);
const state = ref("");
const city = ref("");
const zipCode = ref("");
const phone = ref("");
const secondaryPhone = ref("");
const secondaryEmail = ref("");
const language = ref("");
const languageProficiency = ref("");
const secondLanguage = ref("");
const secondLanguageProficiency = ref("");
const thirdLanguage = ref("");
const thirdLanguageProficiency = ref("");
const emergencyContactName = ref("");
const emergencyContactRelationship = ref("");
const emergencyContactRelationshipOther = ref("");
const emergencyContactPhone = ref("");
const emergencyContactPhoneSecondary = ref("");
const emergencyContactLanguage = ref("");
const secondEmergencyContactName = ref("");
const secondEmergencyContactRelationship = ref("");
const secondEmergencyContactRelationshipOther = ref("");
const secondEmergencyContactPhone = ref("");
const secondEmergencyContactPhoneSecondary = ref("");
const secondEmergencyContactLanguage = ref("");

const nameTitles = [
  "Mr.",
  "Mrs.",
  "Miss",
  "Ms.",
  "Sir",
  "Madam",
  "Master",
  "Dr.",
  "Prof.",
  "Rev.",
  "Capt.",
  "Sgt.",
  "Lord",
  "Dame",
];

const currentDate = ref(new Date().toISOString().slice(0, 10));

const languages = ["English", "Yoruba", "Igbo", "Hausa", "Pidgin"];
const proficiencyLevels = ["Beginner", "Intermediate", "Fluent", "Native"];

const updatePersonalInfo = async () => {
  const userUpdateCredentials = {
    title: title.value,
    first_name: firstName.value,
    last_name: lastName.value,
    date_of_birth: dob.value,
    phone: phone.value,
    secondary_phone: secondaryPhone.value,
    secondary_email: secondaryEmail.value,
    gender: gender.value,
    country: country.value,
    state: state.value,
    city: city.value,
    zip_code: zipCode.value,
    marital_status: maritalStatus.value,
    language: language.value,
    language_proficiency: languageProficiency.value,
    second_language: secondLanguage.value,
    second_language_proficiency: secondLanguageProficiency.value,
    third_language: thirdLanguage.value,
    third_language_proficiency: thirdLanguageProficiency.value,
    emergency_contact_name: emergencyContactName.value,
    emergency_contact_relationship:
      emergencyContactRelationship.value === "Other"
        ? emergencyContactRelationshipOther.value
        : emergencyContactRelationship.value,
    emergency_contact_phone: emergencyContactPhone.value,
    emergency_contact_phone_secondary: emergencyContactPhoneSecondary.value,
    emergency_contact_language: emergencyContactLanguage.value,
    second_emergency_contact_name: secondEmergencyContactName.value,
    second_emergency_contact_relationship:
      secondEmergencyContactRelationship.value === "Other"
        ? secondEmergencyContactRelationshipOther.value
        : secondEmergencyContactRelationship.value,
    second_emergency_contact_phone: secondEmergencyContactPhone.value,
    second_emergency_contact_phone_secondary:
      secondEmergencyContactPhoneSecondary.value,
    second_emergency_contact_language: secondEmergencyContactLanguage.value,
  };
  try {
    isLoading.value = true;
    const response = await updateUserPersonalInfo(userUpdateCredentials);
    if (response.message === "User details updated successfully") {
      push.success("Personal details updated successfully");
    }

    // Reset the emergency contact relationship fields to select
    emergencyContactRelationship.value = "";
    emergencyContactRelationshipOther.value = "";
    secondEmergencyContactRelationship.value = "";
    secondEmergencyContactRelationshipOther.value = "";
  } catch (error) {
    push.error("Error updating personal profile");
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await fetchPatientProfile();

  // Set pre-filled data for the fields
  const profile = getPatientProfile.value;
  if (profile?.person) {
    title.value = profile.person.title || "";
    firstName.value = profile.person.first_name || "";
    lastName.value = profile.person.last_name || "";
    dob.value = profile.person.date_of_birth || "";
    gender.value = profile.person.gender || "";
    maritalStatus.value = profile.person.marital_status || "";
    country.value = profile.person.country || "";
    state.value = profile.person.state || "";
    city.value = profile.person.city || "";
    zipCode.value = profile.person.zip_code || "";
    phone.value = profile.person.phone || "";
    secondaryPhone.value = profile.person.secondary_phone || "";
    secondaryEmail.value = profile.person.secondary_email || "";
    language.value = profile.person.language || "";
    languageProficiency.value = profile.person.language_proficiency || "";
    secondLanguage.value = profile.person.second_language || "";
    secondLanguageProficiency.value =
      profile.person.second_language_proficiency || "";
    thirdLanguage.value = profile.person.third_language || "";
    thirdLanguageProficiency.value =
      profile.person.third_language_proficiency || "";
    emergencyContactName.value = profile.person.emergency_contact_name || "";
    emergencyContactRelationship.value =
      profile.person.emergency_contact_relationship || "";
    emergencyContactPhone.value = profile.person.emergency_contact_phone || "";
    emergencyContactPhoneSecondary.value =
      profile.person.emergency_contact_phone_secondary || "";
    emergencyContactLanguage.value =
      profile.person.emergency_contact_language || "";
    secondEmergencyContactName.value =
      profile.person.second_emergency_contact_name || "";
    secondEmergencyContactRelationship.value =
      profile.person.second_emergency_contact_relationship || "";
    secondEmergencyContactPhone.value =
      profile.person.second_emergency_contact_phone || "";
    secondEmergencyContactPhoneSecondary.value =
      profile.person.second_emergency_contact_phone_secondary || "";
    secondEmergencyContactLanguage.value =
      profile.person.second_emergency_contact_language || "";
  }

  fetch("https://restcountries.com/v3.1/all")
    .then((response) => response.json())
    .then((data) => {
      countries.value = data.map((country) => country.name.common).sort();
    })
    .catch((error) => console.error("Error fetching countries:", error));
});

// Watch for changes to the emergencyContactRelationship to reset the other input field
watch(emergencyContactRelationship, (newVal) => {
  if (newVal !== "Other") {
    emergencyContactRelationshipOther.value = "";
  }
});

watch(secondEmergencyContactRelationship, (newVal) => {
  if (newVal !== "Other") {
    secondEmergencyContactRelationshipOther.value = "";
  }
});
</script>

<style>
.update-profile-btn-wrapper {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}

@media screen and (max-width: 830px) {
  .update-profile-btn-wrapper {
    position: static;
    top: 40px;
    right: 40px;
    z-index: 10;
  }
}
</style>
