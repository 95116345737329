<template>
  <div
    class="fixed top-0 left-0 z-[75] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[1080px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex justify-between items-center mb-14">
          <div class="flex gap-2 md:gap-4 items-center">
            <span class="text-GunMetal text-base md:text-xl font-bold"
              >Test Center</span
            >
            <div
              class="bg-ResolutionBlue text-white text-xs md:text-sm font-bold py-[5px] px-2.5 md:py-[10px] md:px-5 flex justify-center items-center gap-[10px] rounded-[40px] cursor-pointer relative"
              @click="openAddNewPatientModal"
            >
              <img
                src="../../../../../assets/icons/add_icon.svg"
                alt="add icon"
              />
              <span>New Patient</span>
            </div>
          </div>

          <button class="self-start" @click="cancelSearch">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </button>
        </div>

        <div class="flex flex-col md:flex-row gap-16">
          <form
            @submit.prevent="handleSearchPatient"
            class="w-full md:w-1/2 flex flex-col gap-6"
          >
            <div class="flex justify-between items-center">
              <p class="text-DarkJungleGreen text-xs font-semibold">
                Search for Patient
              </p>

              <button
                type="button"
                @click="showFilterDropdown = !showFilterDropdown"
                class="relative"
              >
                <img
                  src="../../../../../assets/icons/filter.svg"
                  alt="filter icon"
                />
                <FilterDropdown
                  v-show="showFilterDropdown"
                  @set-filter="handleSetFilter"
                />
              </button>
            </div>

            <div
              class="grid gap-y-4 lg:gap-y-5 gap-x-2 lg:gap-x-3"
              :class="filters.length > 1 ? 'grid-cols-2' : 'grid-cols-1'"
            >
              <div
                v-for="(filter, index) in filters"
                :key="index"
                class="w-full relative"
              >
                <input
                  :type="
                    filter.value === 'email'
                      ? 'email'
                      : filter.value === 'phone'
                      ? 'number'
                      : 'text'
                  "
                  id="patientName"
                  class="w-full py-2 outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold placeholder:text-[0.625rem] placeholder:font-medium focus:border-ResolutionBlue focus:ring-2"
                  required
                  :placeholder="`Search by ${filter.name}`"
                  :value="options[filter.value]"
                  @input="handleInput($event, filter.value)"
                />
                <button
                  type="button"
                  @click="removeFilter(filter)"
                  class="absolute top-1/2 right-0 -translate-y-1/2 w-4 h-4 flex justify-center items-center"
                >
                  <span class="bg-black w-2.5 h-0.5"></span>
                </button>
              </div>
            </div>

            <div
              class="mt-3 flex items-center gap-[10px] relative text-base text-center font-semibold"
            >
              <button
                type="button"
                class="w-32 p-1.5 md:p-2 border border-ResolutionBlue text-ResolutionBlue rounded-[40px]"
                @click="cancelSearch"
              >
                Cancel
              </button>

              <button
                type="submit"
                class="bg-ResolutionBlue hover:bg-DodgerBlue text-white w-32 p-1.5 md:p-2 border rounded-[40px] transition-colors duration-200"
              >
                Search
              </button>
            </div>
          </form>

          <div class="w-full md:w-1/2">
            <div
              v-show="showPatientsTab"
              class="w-full h-40 py-3 px-4 rounded-lg border border-BluishGrey overflow-y-scroll no-scrollbar text-DarkJungleGreen text-xs md:text-sm"
            >
              <div
                v-if="loading"
                class="w-full h-full flex justify-center items-center"
              >
                <img
                  src="../../../../../assets/icons/loading-01.svg"
                  alt="loader"
                  class="animate-spin mx-auto my-4"
                />
              </div>

              <p
                v-else-if="!loading && results.length === 0"
                class="w-full h-full flex justify-center items-center"
              >
                No patient found
              </p>

              <div
                v-else-if="!loading && results.length > 0"
                class="flex flex-col gap-[10px]"
              >
                <div
                  v-for="result in results"
                  :key="result.id"
                  class="flex items-center gap-4 cursor-pointer"
                  @click="proceedWithTest(result)"
                >
                  <div>
                    <img
                      :src="
                        result.person.avatar_url
                          ? result.person.avatar_url
                          : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                      "
                      alt=""
                      class="w-6 h-6 rounded-full object-cover object-center"
                    />
                  </div>

                  <p>{{ result.person.full_name }}</p>
                </div>
              </div>

              <p
                v-else-if="error"
                class="w-full h-full flex justify-center items-center"
              >
                Something went wrong
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import FilterDropdown from "../dropdowns/FilterDropdown.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

export default {
  name: "SearchPatientModal",
  components: {
    FilterDropdown,
    LoadingSpinner,
  },
  setup() {
    const selectedPatient = inject("selectedPatient");
    const closeSearchPatientModal = inject("closeSearchPatientModal");
    const openAddNewPatientModal = inject("openAddNewPatientModal");
    const openNextModal = inject("openModal");

    const { "user/searchPatientAdmin": searchPatientAdmin } = mapActions();

    const showFilterDropdown = ref(false);
    const filters = ref([
      {
        name: "Full name",
        value: "full_name",
      },
    ]);
    const options = ref({
      full_name: "",
      username: "",
      email: "",
      phone: "",
    });
    const showPatientsTab = ref(false);
    const loading = ref(false);
    const results = ref([]);
    const error = ref(false);

    const handleSetFilter = (value) => {
      const item = filters.value.find((filter) => filter.name === value.name);
      if (!item) {
        filters.value.push(value);
      }
    };

    const removeFilter = (value) => {
      if (filters.value.length > 1) {
        options.value[value.value] = "";
        filters.value = filters.value.filter(
          (filter) => filter.name !== value.name
        );
      } else {
        push.info("Search with at least one parameter");
      }
    };

    const handleInput = (e, option) => {
      options.value[option] = e.target.value;
    };

    const handleSearchPatient = async () => {
      const body = {
        full_name: options.value.full_name,
        username: options.value.username,
        email: options.value.email,
        phone: options.value.phone,
      };

      try {
        showPatientsTab.value = true;
        loading.value = true;
        error.value = false;
        const res = await searchPatientAdmin(body);
        results.value = res.results;
      } catch (error) {
        error.value = true;
        console.log({ error });
        push.error("Error during search");
      } finally {
        loading.value = false;
      }
    };

    const proceedWithTest = (value) => {
      selectedPatient.value = value;
      closeSearchPatientModal();
      openNextModal();
    };

    const cancelSearch = () => {
      showPatientsTab.value = false;
      filters.value = [
        {
          name: "Full name",
          value: "full_name",
        },
      ];
      options.value = {
        full_name: "",
        username: "",
        email: "",
        phone: "",
      };
      closeSearchPatientModal();
    };

    return {
      showFilterDropdown,
      filters,
      options,
      showPatientsTab,
      loading,
      results,
      handleSetFilter,
      removeFilter,
      handleInput,
      handleSearchPatient,
      selectedPatient,
      proceedWithTest,
      openAddNewPatientModal,
      cancelSearch,
    };
  },
};
</script>
