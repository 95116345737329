<template>
  <div class="space-y-5">
    <div>
      <label for="body" class="text-bold text-DarkJungleGreen">Body</label>
      <textarea
        class="font-extralight w-full border border-BattleshipGrey rounded-lg px-1.5 md:px-3 py-1 md:py-2 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-2"
        type="text"
        rows="5"
        name=""
        id="body"
        :value="removeHtmlTags(post?.body)"
        @input="updateBody"
      ></textarea>
    </div>
    <div class="flex justify-center items-center space-x-3">
      <button
        type="button"
        class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
        @click="closeEditBlogModal"
      >
        Cancel
      </button>
      <button
        class="bg-ResolutionBlue text-white w-[147px] p-1.5 md:p-3 border rounded-[44px] text-center text-base font-semibold"
        @click="updateBlogBody"
      >
        <span v-if="isPending">
          <LoadingSpinner />
        </span>
        <span v-else> Update</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { useQuery, useQueryClient, useMutation } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const queryClient = useQueryClient();

const props = defineProps(["post"]);

const closeEditBlogModal = inject("closeEditBlogModal");
const body = ref("");

const updateBody = (event) => {
  body.value = event.target.value;
};

const {
  "blog/fetchBlogPosts": fetchBlogPosts,
  "blog/updateBlogPostBody": updateBlogPostBody,
} = mapActions();

const { isPending, mutate } = useMutation({
  mutationFn: (data) => updateBlogPostBody(data),
  onError: (error) => {
    if (error) {
      push.error("Error updating post");
    }
  },
  onSuccess: (_) => {
    queryClient.invalidateQueries({ queryKey: ["blog-posts"] });
    push.success("Body updated successfully");
    closeEditBlogModal();
  },
});

const updateBlogBody = () => {
  mutate({
    id: props.post.id,
    body: body.value ? body.value : updateBody.value,
  });
};

// remove html tags
const removeHtmlTags = (html) => {
  if (!html) {
    return "";
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

useQuery({
  queryKey: ["blog-posts"],
  queryFn: () => fetchBlogPosts(),
});
</script>
