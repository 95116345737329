<template>
  <ModalWrapper>
    <div
      class="max-w-[724px] w-full p-6 md:p-12 rounded-[48px] bg-white relative"
    >
      <!-- Modal Header -->
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          {{ selectedTestType }} for {{ patientName }}
        </h3>
        <div class="cursor-pointer" @click="closeLinkedAccountManualTestModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <!-- Test Input Section -->
      <div
        class="max-w-md w-full mx-auto flex flex-col items-center gap-[30px]"
      >
        <div
          class="max-w-[414px] w-full h-[186px] border-2 border-ClearBlue flex justify-center items-center rounded-xl"
        >
          <div class="w-[134px] flex gap-3 justify-center items-center">
            <input
              v-model="testValue"
              type="text"
              class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-full bg-transparent border-none outline-none"
              :placeholder="existingData?.glucose_level || '__'"
              @input="updateTestValue"
            />
            <div class="relative">
              <button
                @click="toggleDropdown"
                class="flex items-center justify-center gap-1 py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
                <img
                  src="@/assets/icons/white-down-arrow.svg"
                  alt="unit dropdown"
                  class="ml-1 w-4 h-4"
                  :class="{ 'rotate-180': isDropdownOpen }"
                />
              </button>
              <!-- Custom dropdown menu -->
              <div
                v-if="isDropdownOpen"
                class="absolute top-full left-0 mt-1 w-full bg-white border border-BlueChalk rounded-lg shadow-lg z-50"
              >
                <button
                  v-for="option in unitOptions"
                  :key="option"
                  @click="selectUnit(option)"
                  class="block w-full text-left px-4 py-2 text-sm text-ResolutionBlue hover:bg-BlueChalk"
                  :class="{ 'bg-BlueChalk': units === option }"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Before/After Meal Selection -->
        <div
          class="flex justify-between items-center space-x-2.5 py-1 px-2 rounded-full bg-BlueChalk"
        >
          <button
            @click="setActiveTab('beforeMeal')"
            :class="[
              activeTab === 'beforeMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full cursor-pointer',
            ]"
          >
            Before meal
          </button>
          <button
            @click="setActiveTab('afterMeal')"
            :class="[
              activeTab === 'afterMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full cursor-pointer',
            ]"
          >
            After meal
          </button>
        </div>

        <!-- Date and Time Selection -->
        <div class="w-full flex items-center gap-3">
          <!-- Custom Date Input -->
          <div class="w-full cursor-pointer relative" @click="openDatePicker">
            <input
              type="text"
              :value="formattedDate"
              placeholder="Choose date"
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey outline-none cursor-pointer"
            />
            <img
              src="@/assets/icons/calendar-gray-icon.svg"
              alt="calendar icon"
              class="ml-2 w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
            />
            <!-- Hidden Date Input -->
            <input
              type="date"
              ref="datePicker"
              v-model="selectedDate"
              :max="maxDate()"
              class="w-full absolute inset-0 opacity-0 pointer-events-auto"
            />
          </div>

          <!-- Custom Time Input -->
          <div class="w-full cursor-pointer relative" @click="openTimePicker">
            <input
              type="text"
              :value="formattedTime"
              placeholder="Choose time"
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey outline-none cursor-pointer"
            />
            <img
              src="@/assets/icons/clock.svg"
              alt="clock icon"
              class="ml-2 w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
            />
            <!-- Hidden Time Input -->
            <input
              type="time"
              ref="timePicker"
              v-model="selectedTime"
              class="w-full absolute inset-0 opacity-0 pointer-events-auto"
            />
          </div>
        </div>

        <!-- Submit and Cancel Section -->
        <div class="flex justify-center items-center gap-4 mt-8">
          <button
            @click="closeLinkedAccountManualTestModal"
            class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
          >
            Cancel
          </button>
          <button
            @click="handleSubmit"
            :disabled="isPending || isPending2 || !isFormValid"
            class="px-[52px] py-3 text-white rounded-full bg-ResolutionBlue cursor-pointer text-sm font-semibold disabled:cursor-not-allowed"
          >
            <span v-if="isPending || isPending2">
              <LoadingSpinner />
            </span>
            <span v-else>Save</span>
          </button>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref, computed, inject, watch, onMounted } from "vue";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import { validateInput } from "@/utils/manualTestInputValidation";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import ModalWrapper from "./ModalWrapper.vue";
import { maxDate } from "@/utils/dateFormatter";

const queryClient = useQueryClient();

const {
  "test/editATestForLinkedPatient": editATestForLinkedPatient,
  "test/submitTestResultForPatient": submitTestResultForPatient,
} = mapActions();

// Props received for patient
const props = defineProps({
  patientId: String,
  selectedTestType: String,
  patientName: String,
  existingData: Object,
});

const testValue = ref(props.existingData?.glucose_level || "");
const selectedDate = ref(""); // Raw date value
const selectedTime = ref(""); // Raw time value
const formattedDate = ref(""); // Formatted date for display
const formattedTime = ref(""); // Formatted time for display
const activeTab = ref("beforeMeal"); // Managing which tab is active
const units = ref("mg/dl");
const unitOptions = ["mg/dl", "mmol/l"];
const isDropdownOpen = ref(false);

// References for the hidden input elements
const datePicker = ref(null);
const timePicker = ref(null);

// Computed property for form validation
const isFormValid = computed(() => {
  return testValue.value && formattedDate.value && formattedTime.value;
});

// Functions
const closeLinkedAccountManualTestModal = inject(
  "closeLinkedAccountManualTestModal"
);

// Method to change the active tab
const setActiveTab = (tab) => {
  activeTab.value = tab;
};

// Function to toggle dropdown
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

// Function to select a unit
const selectUnit = (newUnit) => {
  units.value = newUnit;
  isDropdownOpen.value = false;
};

// Method to open hidden date picker
const openDatePicker = () => {
  datePicker.value.click();
};

// Method to open hidden time picker
const openTimePicker = () => {
  timePicker.value.click();
};

// Method to handle date change
const handleDateChange = () => {
  if (selectedDate.value) {
    const date = new Date(selectedDate.value);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    formattedDate.value = `${day}-${month}-${year}`;
  }
};

// Method to handle time change
const handleTimeChange = () => {
  if (selectedTime.value) {
    const time = new Date(`1970-01-01T${selectedTime.value}:00`);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    formattedTime.value = `${hours}:${minutes}:${seconds}`;
  }
};

// Method to validate and update test value
const updateTestValue = (event) => {
  testValue.value = validateInput(event.target.value); // Use the utility function
};

// Watch for changes in existingData and populate the form fields
watch(
  () => props.existingData,
  (newData) => {
    if (newData) {
      populateFormFields(newData);
    }
  },
  { immediate: true } // Watch immediately after the component is created
);

// Populate the form fields with the existing data
const populateFormFields = () => {
  if (props.existingData) {
    testValue.value = props.existingData?.glucose_level || "";

    if (props.existingData?.check_time) {
      const checkTime = new Date(props.existingData?.check_time);
      const year = checkTime.getFullYear();
      const month = (checkTime.getMonth() + 1).toString().padStart(2, "0");
      const day = checkTime.getDate().toString().padStart(2, "0");
      selectedDate.value = `${year}-${month}-${day}`;

      const hours = checkTime.getHours().toString().padStart(2, "0");
      const minutes = checkTime.getMinutes().toString().padStart(2, "0");
      selectedTime.value = `${hours}:${minutes}`;
    }

    activeTab.value = props.existingData?.before_meal
      ? "beforeMeal"
      : "afterMeal";
    units.value = props.existingData.units || "mg/dl";

    handleDateChange();
    handleTimeChange();
  }
};

// Automatically populate fields when the component is mounted
onMounted(() => {
  populateFormFields();
});

const { isPending, mutate } = useMutation({
  mutationFn: (data) => editATestForLinkedPatient(data),
  onError: (error) => {
    if (error) {
      push.error(error.response?.data?.message || "Error submitting the form.");
    }
  },
  onSuccess: (_) => {
    push.success("Test result edited successfully!");
    queryClient.invalidateQueries({
      queryKey: ["linked-account-vitals", props.patientId],
    });
    // Close the modal after successful submission
    closeLinkedAccountManualTestModal();
  },
});

const { isPending: isPending2, mutate: mutate2 } = useMutation({
  mutationFn: (data) => submitTestResultForPatient(data),
  onError: (error) => {
    if (error) {
      push.error(error.response?.data?.message || "Error submitting the form.");
    }
  },
  onSuccess: (_) => {
    push.success("Test result submitted successfully!");
    queryClient.invalidateQueries({
      queryKey: ["linked-account-vitals", props.patientId],
    });
    // Close the modal after successful submission
    closeLinkedAccountManualTestModal();
  },
});

// Submit Test Result
const handleSubmit = async () => {
  if (!isFormValid.value) {
    return;
  }

  const patientId = props.patientId;

  // Prepare the payload with beforeOrAfterMeals based on activeTab
  const payload = {
    Check_Time: `${formattedDate.value} ${formattedTime.value}`, // Combine date and time for Check_Time
    bloodSugar: testValue.value,
    beforeOrAfterMeals: activeTab.value === "beforeMeal" ? 1 : 0, // Update based on activeTab
    units: units.value,
  };

  if (props.existingData && props.existingData?.glucose_level_id && patientId) {
    // If editing, call the edit action
    mutate({
      glucoseLevelId: props.existingData?.glucose_level_id, // Ensure glucose_level_id is passed with existing data
      patientId,
      payload,
    });
  } else {
    // If creating a new test
    mutate2({
      patientId,
      payload,
    });
  }
};

// Watch for changes in selectedDate and selectedTime
watch(selectedDate, handleDateChange);
watch(selectedTime, handleTimeChange);
</script>

<style scoped>
.input-with-dashes::placeholder {
  text-align: center;
  color: #011d7e;
}
</style>
