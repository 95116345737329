<template>
  <td class="min-w-[180px] h-full py-2.5 border-t border-gray-200 text-sm">
    <div class="flex items-center cursor-pointer">
      <div class="flex-shrink-0 w-7 h-7">
        <img
          class="w-full h-full rounded-full object-cover"
          :src="
            currentAccount?.avatar_url ||
            account?.health_centre?.avatar_url ||
            account?.avatar_url ||
            noAvatar
          "
          alt="profile picture"
        />
      </div>

      <div class="ml-3">
        <router-link
          :to="routeToAccountDetails"
          class="font-bold text-DavyGrey whitespace-no-wrap"
        >
          {{
            currentAccount?.full_name ||
            account?.health_centre?.name ||
            account?.name
          }}
        </router-link>
      </div>
    </div>
  </td>
  <td
    class="min-w-[100px] pl-5 py-2.5 border-t text-DavyGrey border-gray-200 text-sm"
  >
    {{ account?.relationship }}
  </td>
  <td
    class="min-w-[200px] pl-5 py-2.5 border-t text-DavyGrey border-gray-200 text-sm"
  >
    {{ formattedDate }}
  </td>
  <td
    class="min-w-[100px] pl-5 py-2.5 border-t text-DavyGrey border-gray-200 text-sm"
  >
    {{ formattedTime }}
  </td>
  <td class="min-w-[100px] pr-4 py-2.5 border-t border-gray-200 text-right">
    <button
      @click="openUnlinkPatientModal(account)"
      class="text-ArtyClickRed border border-ArtyClickRed px-3 py-1 rounded-3xl text-xs font-medium hover:border-ArtyClickRed hover:bg-ArtyClickRed hover:text-white transition-all duration-300 w-16"
    >
      <span v-if="isLoading" class="pl-3">
        <LoadingSpinner />
      </span>
      <span v-else>unlink</span>
    </button>
  </td>
</template>

<script setup>
import { ref, inject, computed } from "vue";
import { mapGetters } from "@/hooks/mapStore";
import { dateFormatter_3 } from "@/utils/dateFormatter";
import { timeFormatter_3 } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const props = defineProps(["account"]);

const { "user/getPatientId": patientId } = mapGetters();

const getAccount = (account) => {
  //if user is the one who sent the connection request return recipient details
  if (account?.sender_id === patientId.value) {
    return account?.patient_recipient;
    // otherwise return sender details
  } else {
    return account?.patient_sender;
  }
};

const currentAccount = getAccount(props.account);

const isLoading = ref(false);

const openUnlinkPatientModal = inject("openUnlinkPatientModal");

const formattedDate = computed(() => {
  const date = props.account?.updated_at || props.account?.patient?.updated_at;
  return dateFormatter_3(date);
});

const formattedTime = computed(() => {
  const date = props.account?.updated_at || props.account?.patient?.updated_at;
  return timeFormatter_3(date);
});

const routeToAccountDetails = computed(() => {
  if (currentAccount?.full_name) {
    return `/patient/settings/account-linking/patient/${currentAccount.id}`;
  } else if (props.account?.health_centre?.id) {
    return `/patient/settings/account-linking/center/${props.account.health_centre.id}`;
  } else if (props.account?.id) {
    return `/patient/settings/account-linking/center/${props.account?.id}`;
  }
  return "#";
});
</script>
