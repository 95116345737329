<template>
  <div class="flex items-center justify-between border-b pb-3">
    <div class="flex items-center cursor-pointer">
      <div class="flex-shrink-0 w-10 h-10">
        <img
          class="w-full h-full rounded-full object-cover"
          :src="
            connections_received?.patient_sender?.avatar_url ||
            connections_received?.health_centre?.avatar_url ||
            noAvatar
          "
          alt=""
        />
      </div>
      <div class="ml-3">
        <p class="font-light whitespace-no-wrap">
          <span class="font-bold text-ResolutionBlue">{{
            connections_received?.patient_sender?.full_name ||
            connections_received?.health_centre.name
          }}</span>
          invited you to be linked as their
          <span class="font-bold">{{
            connections_received?.relationship
          }}</span>
        </p>
        <div
          class="flex items-center space-x-6 text-SantaGrey font-extralight text-sm"
        >
          <span> {{ dateFormatter_2(connections_received?.created_at) }}</span>
          <div class="flex items-center">
            <span>・</span>
            <span>{{ timeFormatter_2(connections_received?.created_at) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center space-x-2">
      <button
        @click="acceptRequest"
        class="bg-ResolutionBlue text-white px-3 py-2 rounded-3xl text-xs font-medium w-16 flex justify-center items-center"
      >
        <span v-if="isPending" class="pl-3">
          <LoadingSpinner />
        </span>
        <span v-else>Accept</span>
      </button>
      <button
        @click="denyRequest"
        class="border border-ArtyClickRed px-3 py-2 rounded-3xl text-xs font-medium hover:border-ArtyClickRed hover:bg-ArtyClickRed hover:text-white transition-all duration-300"
      >
        <span v-if="isPending2" class="pl-3">
          <LoadingSpinner />
        </span>
        <span v-else>Decline</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import { timeFormatter_2 } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const queryClient = useQueryClient();

const props = defineProps(["connections_received"]);

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const { "accountConnection/acceptPatientRequest": acceptPatientRequest } =
  mapActions();

const { isPending, mutate } = useMutation({
  mutationFn: (data) => acceptPatientRequest(data),
  onError: (error) => {
    if (error) {
      const errorMessage = error.response
        ? error.response.data?.message
        : error?.message;
      if (typeof errorMessage === "string") {
        push.error(errorMessage);
      } else {
        push.error("Error accepting request");
      }
    }
  },
  onSuccess: (_) => {
    queryClient.invalidateQueries({ queryKey: ["linked-accounts"] });
    push.success("Request accepted successfully");
  },
});

const acceptRequest = async () => {
  mutate({
    response: "accept",
    id: props.connections_received.id,
  });
};

const { isPending: isPending2, mutate: mutate2 } = useMutation({
  mutationFn: (data) => acceptPatientRequest(data),
  onError: (error) => {
    if (error) {
      const errorMessage = error.response
        ? error.response.data?.message
        : error?.message;
      if (typeof errorMessage === "string") {
        push.error(errorMessage);
      } else {
        push.error("Error denying request");
      }
    }
  },
  onSuccess: (_) => {
    queryClient.invalidateQueries({ queryKey: ["linked-accounts"] });
    push.success("Request denied successfully");
  },
});

const denyRequest = async () => {
  mutate2({
    response: "deny",
    id: props.connections_received.id,
  });
};
</script>
