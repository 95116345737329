<template>
  <div class="w-full h-screen flex justify-center items-center px-4">
    <div>
      <div v-if="loading" class="text-center">
        <LoadingSpinner />
        <p class="text-OlsoGrey text-base mt-4">Verifying your email...</p>
      </div>

      <div v-else>
        <div class="text-center">
          <router-link to="/">
            <img
              src="../../../assets/icons/turbomedic-logo__.svg"
              alt="logo"
              class="w-32 md:w-40 mx-auto"
            />
          </router-link>
          <p class="text-ResolutionBlue mt-2">Your health directed by YOU</p>
        </div>

        <div class="text-center mt-20">
          <div>
            <div class="flex justify-center">
              <div
                class="w-20 h-20 bg-ResolutionBlue rounded-full flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  class="w-10 h-10"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <h1 class="text-black text-2xl font-bold mt-6">Success</h1>
            <p class="text-OlsoGrey text-base mt-2">
              Your account has been verified successfully
            </p>
            <router-link
              to="/auth/sign-in"
              class="mt-6 inline-block bg-ResolutionBlue text-white py-2 px-4 rounded-full font-semibold w-[335px]"
            >
              Return to Login
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

export default {
  name: "EmailVerified",
  components: {
    LoadingSpinner,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loading = ref(true);

    const { "auth/verifyEmail": verifyEmail } = mapActions();

    onMounted(async () => {
      const email = route.query.email;
      const token = route.query.token;

      if (!email || !token) {
        router.push("/404");
        return;
      }

      try {
        await verifyEmail({ email, token });
        push.success("Email verified successfully");
      } catch (error) {
        if (
          error.response?.status === 410 ||
          error.response?.data?.message?.includes("expired")
        ) {
          router.push("/auth/email-expired");
        } else {
          push.error("Email verification failed. Please try again.");
          router.push("/auth/verify-email?email=" + encodeURIComponent(email));
        }
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
    };
  },
};
</script>
