<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full h-full bg-white p-4 md:p-10"
    >
      <div class="w-full h-full flex flex-col">
        <div class="w-full border rounded-3xl p-5 h-full overflow-x-auto ">
          <!-- Reusable FilterHeader Component -->
          <FilterHeader
            title="Test Log"
            :selectedFilter="selectedFilter"
            :selectedTestType="selectedTestType"
            :showFilterDropdown="showFilterDropdown"
            :showTestTypeDropdown="showTestTypeDropdown"
            :average_high="average_high"
            @toggleFilterDropdown="toggleFilterDropdown"
            @toggleTestTypeDropdown="toggleTestTypeDropdown"
            @setFilter="setFilter"
            @setTestType="setTestType"
            @exportToPDF="exportToPDF"
          />

          <!-- Table with fetched records -->
         
            <div class="overflow-x-auto h-full flex flex-col py-6">
              <Table
                v-if="!isFetching && sortedRecords.length > 0"
                :columns="columns"
                :tableData="sortedRecords"
              />

              <div
                v-if="isFetching"
                class="self-center flex justify-center items-center w-6 h-6"
              >
                <SpinnerIcon />
              </div>

              <ErrorMessage v-else-if="isError && !isFetching" />

              <NoDataMessage
                v-else-if="!isFetching && sortedRecords?.length === 0"
                text="You have no tests vitals"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
 
</template>

<script setup>
import { watchEffect, ref, computed, inject, watch, h } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { dateFormatter, timeFormatter } from "@/utils/dateFormatter";
import { testTypes } from "@/utils/mockData/testTypes";
import FilterHeader from "@/components/main/patient/testRecords/FilterHeader.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";

const { "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords } =
  mapActions();

const openEditManualTestModal = inject("openEditManualTestModal");

const hypoglycaemic = ref(70);
const normal = ref(100);
const average_high = ref(125);
const high = ref(126);

const selectedTestType = ref(testTypes[0]);
const showTestTypeDropdown = ref(false);
const selectedFilter = ref("All");
const showFilterDropdown = ref(false);
const sortField = ref(null);
const sortDirection = ref(null);
const columns = ref([]);
const isSwitchingTestType = ref(false);

const toggleFilterDropdown = () => {
  showFilterDropdown.value = !showFilterDropdown.value;
};

const toggleTestTypeDropdown = () => {
  showTestTypeDropdown.value = !showTestTypeDropdown.value;
};

const setFilter = (filter) => {
  selectedFilter.value = filter;
  showFilterDropdown.value = false;
};

const setTestType = (testType) => {
  selectedTestType.value = testType;
  showTestTypeDropdown.value = false;
  updateColumns(); // Update columns whenever test type changes
  refetch(); // Refetch data based on new test type
};

// Fetch blood glucose records with query
const {
  data: glucoseData,
  isFetching,
  isError,
  refetch, // This will allow us to refetch the query when needed
} = useQuery({
  queryKey: [
    "bloodGlucoseRecords",
    selectedTestType.value.type,
    selectedFilter.value,
  ],
  queryFn: () =>
    fetchBloodGlucoseRecords({
      test_type: selectedTestType.value.type,
      time_period:
        selectedFilter.value === "All"
          ? null
          : selectedFilter.value.toLowerCase().replace(" ", "_"),
    }),
  onSettled: () => {
    isSwitchingTestType.value = false; // End switching when data is ready
  },
});

// Trigger refetch when test type or filter changes
watch([selectedTestType, selectedFilter], () => {
  refetch();
});

// Dynamically set columns based on selected test type
const updateColumns = () => {
  const testType = selectedTestType.value.type;

  // Define the "Date" column to come first
  const dateColumn = {
    title: "Date",
    dataIndex: "check_time",
    render: (record) => h("span", null, dateFormatter(record.check_time)),
  };

  // Define specific columns based on test type
  const testTypeColumns = {
    glucose: [
      {
        title: "Glucose Level",
        dataIndex: "glucose_level",
        render: (record) => {
          const glucoseClass =
            record.glucose_level < 70
              ? "text-DodgerBlue font-bold"
              : record.glucose_level <= 100
              ? "text-DarkMint font-bold"
              : record.glucose_level <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: glucoseClass },
            `${record.glucose_level} ${record.units}`
          );
        },
      },
      {
        title: "Meal",
        dataIndex: "before_meal",
        render: (record) =>
          h("span", null, record.before_meal ? "Before meal" : "After meal"),
      },
    ],
    heart_rate: [
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
      {
        title: "Oxygen Saturation",
        dataIndex: "oxygen_saturation",
        render: (record) =>
          h(
            "span",
            null,
            `${record.oxygen_saturation} ${record.oxygen_saturation_unit}`
          ),
      },
      {
        title: "Perfusion Index",
        dataIndex: "perfusion_index",
        render: (record) =>
          h(
            "span",
            null,
            `${record.perfusion_index} ${record.perfusion_index_unit}`
          ),
      },
    ],
    blood_pressure: [
      {
        title: "Systolic",
        dataIndex: "systolic",
        render: (record) =>
          h("span", null, `${record.systolic} ${record.units}`),
      },
      {
        title: "Diastolic",
        dataIndex: "diastolic",
        render: (record) =>
          h("span", null, `${record.diastolic} ${record.units}`),
      },
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
    ],
    weight: [
      {
        title: "Weight",
        dataIndex: "weight",
        render: (record) => h("span", null, `${record.weight} ${record.units}`),
      },
    ],
    temperature: [
      {
        title: "Temperature",
        dataIndex: "temperature",
        render: (record) => {
          const temperatureClass =
            record.temperature < 70
              ? "text-DodgerBlue font-bold"
              : record.temperature <= 100
              ? "text-DarkMint font-bold"
              : record.temperature <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: temperatureClass },
            `${record.temperature} ${record.units}`
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => h("span", null, `${record.status}`),
      },
      {
        title: "Description",
        dataIndex: "description",
        render: (record) => h("span", null, `${record.description}`),
      },
    ],
    ecg: [
      {
        title: "Heart Rate",
        dataIndex: "heart_rate",
        render: (record) => {
          const heartClass =
            record.heart_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.heart_rate <= 100
              ? "text-DarkMint font-bold"
              : record.heart_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: heartClass },
            `${record.heart_rate} ${record.units}`
          );
        },
      },
      {
        title: "Analysis",
        dataIndex: "analysis",
        render: (record) => h("span", null, `${record.analysis}`),
      },
    ],
  };

  // Common columns for all test types
  const commonColumns = [
    {
      title: "Time",
      dataIndex: "check_time",
      render: (record) => h("span", null, timeFormatter(record.check_time)),
    },
    {
      title: "Input",
      dataIndex: "input",
    },
    {
      title: "Date Updated",
      dataIndex: "updated_at",
      render: (record) => {
        return h("span", null, dateFormatter(record.updated_at));
      },
    },
    {
      title: "",
      render: (record) =>
        record.input === "Manual Input"
          ? h(
              "button",
              {
                class:
                  "border border-ResolutionBlue rounded-2xl text-ResolutionBlue px-4 py-1 font-bold hover:bg-ResolutionBlue hover:text-white",
                onClick: () => handleEditClick(record),
              },
              "Edit"
            )
          : null,
    },
  ];

  // Combine columns with the "Date" column as the first column
  columns.value = [
    dateColumn,
    ...(testTypeColumns[testType] || []),
    ...commonColumns,
  ];
};

// Initialize columns on load and update when test type changes
watch(selectedTestType, updateColumns, { immediate: true });

// Define sortedRecords as a computed property that watches glucoseData changes
const sortedRecords = computed(() => {
  // Ensure glucoseData is available before proceeding
  if (!glucoseData.value || !Array.isArray(glucoseData.value.results)) {
    return [];
  }

  const records = glucoseData.value.results;

  // Apply sorting logic
  if (!sortField.value || !sortDirection.value) {
    return records;
  }

  return records.sort((a, b) => {
    let fieldA = a[sortField.value];
    let fieldB = b[sortField.value];

    // Handle string comparisons
    if (typeof fieldA === "string") {
      fieldA = fieldA.toLowerCase();
      fieldB = fieldB.toLowerCase();
    }

    if (sortDirection.value === "asc") {
      return fieldA > fieldB ? 1 : -1;
    } else if (sortDirection.value === "desc") {
      return fieldA < fieldB ? 1 : -1;
    }
  });
});

const exportToPDF = () => {
  const doc = new jsPDF();
  const img = new Image();
  img.src = require("@/assets/images/turbomedic-logo.png");

  img.onload = () => {
    doc.addImage(img, "PNG", 10, 10, 50, 15);
    doc.setFontSize(18);
    doc.text("Blood Glucose History", 70, 30);

    const tableColumn = [
      "Glucose Level",
      "Meal",
      "Date",
      "Time",
      "Input",
      "Date Updated",
    ];
    const tableRows = [];

    sortedRecords.value.forEach((record) => {
      const glucoseLevel = `${record.glucose_level} mg/dl`;
      const meal = record.before_meal ? "Before meal" : "After meal";
      const date = dateFormatter(record.check_time);
      const time = timeFormatter(record.check_time);
      const input = record.input;
      const date_taken = record.updated_at;

      const rowData = [glucoseLevel, meal, date, time, input];
      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 40,
    });

    doc.save("glucose_history.pdf");
  };
};

// Open the ManualTestModal with the data of the clicked glucose record
const handleEditClick = (glucose_record) => {
  const glucoseLevelId = glucose_record.id;
  const patientId = glucose_record.patient_id;
  const editingData = {
    glucose_level: glucose_record.glucose_level,
    before_meal: glucose_record.before_meal,
    check_time: glucose_record.check_time,
    input: glucose_record.input,
    units: glucose_record.units,
    created_at: glucose_record.created_at,
    glucose_level_id: glucoseLevelId,
  };

  openEditManualTestModal("Manual Test", patientId, editingData);
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
.opacity-50 {
  opacity: 0.5;
}
</style>
