<template>
  <div>
    <div class="w-full border px-4 md:px-16 py-10 space-y-10 rounded-xl">
      <div
        class="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-between items-center mb-3 md:mb-10"
      >
        <p class="font-urbanist-regular font-bold text-lg md:text-xl">
          Patients
        </p>

        <!-- <div class="flex flex-row flex-wrap gap-3">
            <div
              class="md:w-80 flex flex-row items-center justify-between border border-[#CFD3D5] rounded-md px-3"
            >
              <div class="flex items-center">
                <img src="../../../assets/icons/Search.svg" alt="" />
                <input
                  type="password"
                  name=""
                  id=""
                  class="bg-transparent focus:outline-0 px-3 w-fit text-xs"
                  placeholder="Search"
                />
              </div>
            </div>
            <button
              class="border-[0.1px] border-[#8B8D97] rounded-md px-4 py-1.5 text-xs"
            >
              Today
            </button>
          </div> -->
      </div>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr>
              <th
                class="min-w-[200px] md:min-w-[150px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
              >
                Name
              </th>
              <th
                class="min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
              >
                Gender
              </th>
              <th
                class="min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
              >
                Latest Test
              </th>
              <th
                class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
              >
                Reading
              </th>
              <th
                class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
              >
                Vitals
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="patient in getPatientsList?.patients"
              :key="patient.id"
              class="font-light border-b"
            >
              <td
                class="min-w-[200px] md:min-w-[150px] py-3 border-t border-gray-200 text-sm"
              >
                <div
                  class="flex items-center cursor-pointer"
                  @click="(showModal = true), selectedPatientInfo(patient)"
                >
                  <div class="flex-shrink-0 w-8 h-8">
                    <img
                      class="w-full h-full rounded-full object-cover"
                      :src="
                        patient?.person?.has_avatar
                          ? patient?.person?.avatar_url
                          : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                      "
                      :alt="patient?.person?.full_name"
                    />
                  </div>
                  <div class="ml-2">
                    <p class="font-semibold text-DavyGrey whitespace-no-wrap">
                      {{ patient?.person.full_name }}
                    </p>
                  </div>
                </div>
              </td>

              <td
                class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
              >
                {{ patient?.person.gender ? patient?.person.gender : "--" }}
              </td>

              <td
                class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
              >
                Blood Glocose
              </td>

              <td
                class="min-w-[130px] py-3 border-t border-gray-200 text-sm"
                :class="[
                  patient?.glucose_records?.[0]?.glucose_level < hypoglycaemic
                    ? 'text-DodgerBlue'
                    : patient?.glucose_records?.[0]?.glucose_level >=
                        hypoglycaemic &&
                      patient?.glucose_records?.[0]?.glucose_level <= normal
                    ? 'text-DarkMint'
                    : patient?.glucose_records?.[0]?.glucose_level > normal &&
                      patient?.glucose_records?.[0]?.glucose_level <=
                        average_high
                    ? 'text-OrangePeel'
                    : patient?.glucose_records?.[0]?.glucose_level >= high
                    ? 'text-ArtyClickRed'
                    : '',
                ]"
              >
                {{
                  patient?.glucose_records?.[0]?.glucose_level
                    ? patient?.glucose_records?.[0]?.glucose_level
                    : "--"
                }}
                {{ patient?.glucose_records?.[0]?.glucose_level && " mg/dl" }}
              </td>

              <td
                class="min-w-[130px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
              >
                <router-link
                  :to="`/admin/vital-details/${patient?.person?.full_name}`"
                  class="text-DodgerBlue underline"
                >
                  View
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-show="totalPages > 1" class="flex space-x-3">
        <div class="flex flex-row flex-wrap gap-x-1.5 font-light text-xs">
          <button
            @click="goToPage(page - 1)"
            :disabled="page === 1"
            :class="{ 'cursor-not-allowed opacity-40': page === 1 }"
            class="flex justify-center items-center w-10 h-10 border rounded"
          >
            <DirectionalLeftIcon width="17" height="14" />
          </button>

          <button
            v-for="pageNumber in totalPages"
            :key="pageNumber"
            :class="[
              'w-10 h-10 border rounded text-BalticSea',
              { 'bg-ResolutionBlue text-white': page === pageNumber },
            ]"
            @click="goToPage(pageNumber)"
          >
            {{ pageNumber }}
          </button>

          <button
            @click="goToPage(page + 1)"
            :disabled="page === totalPages"
            class="flex justify-center items-center w-10 h-10 border rounded"
            :class="{ 'cursor-not-allowed opacity-40': page === totalPages }"
          >
            <DirectionalRightIcon width="8" height="8" />
          </button>
        </div>

        <div class="text-xs text-IronsideGrey mt-2">
          <span>of </span>
          <span>{{ totalPages }} pages</span>
        </div>
      </div>
    </div>

    <div>
      <transition name="fade" appear>
        <div
          class="modal-overlay"
          v-if="showModal"
          @click="showModal = false"
        ></div>
      </transition>

      <transition name="pop">
        <div
          class="modal overflow-y-auto h-[75%] rounded-[48px]"
          role="dialog"
          v-if="showModal"
        >
          <div class="flex flex-row justify-between items-center mb-3 md:mb-6">
            <div class="flex items-center space-x-3">
              <div class="flex items-center space-x-3">
                <button
                  :disabled="step === 1"
                  class="font-semibold cursor-pointer"
                  :class="[
                    step === 1 ? 'text-ResolutionBlue' : 'text-OlsoGrey',
                  ]"
                  @click="step--"
                >
                  Patient Information
                </button>
              </div>

              <div v-if="step === 2" class="flex items-center space-x-3">
                <DirectionalRightIcon width="8" height="12" />
                <button
                  :disabled="step === 1"
                  class="text-ResolutionBlue font-semibold cursor-pointer"
                >
                  Add Device
                </button>
              </div>

              <router-link
                :to="`/admin/settings/${selectedPatient?.person.full_name}`"
              >
                <img
                  src="../../../assets/icons/open_in_full_icon.svg"
                  class="p-2 rounded-md border-[1.5px] border-ResolutionBlue"
                  alt=""
                />
              </router-link>
            </div>

            <button @click="showModal = false">
              <CloseModalIcon />
            </button>
          </div>

          <div v-if="step === 1">
            <div class="flex flex-row items-center space-x-4 mb-6 md:mb-6">
              <div class="w-16 h-16">
                <img
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  class="w-full h-full rounded-full"
                  alt=""
                />
              </div>

              <div class="flex flex-col">
                <p class="font-bold text-DarkJungle text-lg md:text-2xl">
                  {{ selectedPatient?.person.full_name }}
                </p>
                <p class="text-OlsoGrey text-sm md:text-base font-extralight">
                  {{ selectedPatient?.person.email }}
                </p>
              </div>
            </div>

            <div
              class="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:items-start md:space-x-6"
            >
              <div class="w-full md:w-5/12">
                <p class="text-OlsoGrey font-semibold mb-2 md:mb-4">
                  Profile Information
                </p>

                <div
                  class="grid grid-cols-2 gap-y-8 bg-WhiteLilac p-4 rounded-lg"
                >
                  <div class="space-y-0.5">
                    <p class="text-BluishGrey text-[10px] md:text-xs">
                      Date of Birth
                    </p>
                    <p class="text-Mirage text-xs md:text-sm font-semibold">
                      {{
                        selectedPatient?.person.date_of_birth
                          ? selectedPatient?.person.date_of_birth
                          : "--"
                      }}
                    </p>
                  </div>
                  <div class="space-y-0.5">
                    <p class="text-BluishGrey text-[10px] md:text-xs">Age</p>
                    <p class="text-Mirage text-xs md:text-sm font-semibold">
                      {{
                        selectedPatient?.person.age
                          ? selectedPatient?.person.age
                          : "--"
                      }}
                    </p>
                  </div>
                  <div class="space-y-0.5">
                    <p class="text-BluishGrey text-[10px] md:text-xs">Gender</p>
                    <p class="text-Mirage text-xs md:text-sm font-semibold">
                      {{
                        selectedPatient?.person.gender
                          ? selectedPatient?.person.gender
                          : "--"
                      }}
                    </p>
                  </div>

                  <div class="space-y-0.5">
                    <p class="text-BluishGrey text-[10px] md:text-xs">
                      Phone Number
                    </p>
                    <p class="text-Mirage text-xs md:text-sm font-semibold">
                      {{
                        selectedPatient?.person.phone
                          ? selectedPatient?.person.phone
                          : "--"
                      }}
                    </p>
                  </div>
                  <div class="space-y-0.5">
                    <p class="text-BluishGrey text-[10px] md:text-xs">City</p>
                    <p class="text-Mirage text-xs md:text-sm font-semibold">
                      {{
                        selectedPatient?.person.city
                          ? selectedPatient?.person.city
                          : "--"
                      }}
                    </p>
                  </div>
                  <div class="space-y-0.5">
                    <p class="text-BluishGrey text-[10px] md:text-xs">State</p>
                    <p class="text-Mirage text-xs md:text-sm font-semibold">
                      {{
                        selectedPatient?.person.state
                          ? selectedPatient?.person.state
                          : "--"
                      }}
                    </p>
                  </div>
                  <div class="space-y-0.5 col-span-2">
                    <p class="text-BluishGrey text-[10px] md:text-xs">
                      Address
                    </p>
                    <p class="text-Mirage text-xs md:text-sm font-semibold">
                      {{
                        selectedPatient?.person.street
                          ? selectedPatient?.person.street
                          : "--"
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="w-full md:w-7/12">
                <div
                  class="flex flex-row items-center justify-between mb-2 md:mb-4"
                >
                  <p class="text-OlsoGrey font-semibold">
                    {{
                      selectedPatient?.medical_devices.length > 0
                        ? "Linked Devices"
                        : "Link Device"
                    }}
                  </p>
                  <button @click="step++">
                    <AddNewDeviceIcon />
                  </button>
                </div>

                <div class="bg-WhiteLilac p-4 rounded-md">
                  <div
                    class="space-y-3.5"
                    v-if="selectedPatient?.medical_devices.length > 0"
                  >
                    <div
                      v-for="(
                        device, index
                      ) in selectedPatient?.medical_devices"
                      :key="index"
                      class="w-full flex flex-row items-center space-x-5 bg-white rounded-lg px-3 py-2"
                    >
                      <div class="w-[12%]">
                        <div class="h-12 w-12 rounded border">
                          <img
                            src="../../../assets/icons/small_device_image1.svg"
                            class="w-full h-full object-contain"
                            alt=""
                          />
                        </div>
                      </div>

                      <div
                        class="w-[88%] flex items-end justify-between space-x-4"
                      >
                        <div class="flex flex-col space-y-1">
                          <p class="text-BluishGrey text-[10px] md:text-xs">
                            Blood Glucose
                          </p>
                          <p
                            class="font-bold text-ResolutionBlue text-xs md:text-sm"
                          >
                            4G Blood Glucometer
                          </p>
                        </div>

                        <div class="flex items-center space-x-3">
                          <button
                            @click="unlinkTheDevice(device.id)"
                            class="font-light text-ArtyClickRed text-[10px]"
                          >
                            unlink Device
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-row justify-center items-center" v-else>
                    <div
                      class="flex flex-col justify-center items-center py-16 space-y-4"
                    >
                      <div
                        class="w-fit bg-BlueChalk p-6 rounded-full flex justify-center items-center"
                      >
                        <DeviceIcon />
                      </div>
                      <p class="text-DavyGrey">You have no device yet</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="step === 2">
            <div
              class="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-start md:space-x-16 pt-5"
            >
              <div class="w-full md:w-7/12">
                <p class="text-OlsoGrey font-semibold pt-2">Devices Details</p>

                <div class="space-y-2 md:space-y-6">
                  <div>
                    <label for="email" class="text-sm text-BluishGrey"
                      >Device IMEI</label
                    >
                    <input
                      class="text-sm md:text-[14px] pb-1 w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 placeholder:text-OlsoGrey"
                      type="text"
                      name="imei"
                      v-model="imei"
                    />
                  </div>
                  <div>
                    <label for="email" class="text-sm text-BluishGrey"
                      >SIM Number</label
                    >
                    <input
                      class="text-sm md:text-[14px] pb-1 w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 placeholder:text-OlsoGrey"
                      type="text"
                      name="imei"
                      v-model="sn"
                    />
                  </div>
                  <div class="space-y-2">
                    <label for="email" class="text-sm text-BluishGrey"
                      >Category</label
                    >
                    <input
                      class="bg-transparent text-sm md:text-[14px] pb-1 w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 placeholder:text-OlsoGrey"
                      type="text"
                      name="imei"
                      placeholder="4G Blood Glucometer"
                      disabled
                    />
                  </div>
                </div>

                <button
                  @click="addPatientNewDevice"
                  :disabled="isLoading"
                  class="w-44 flex justify-center items-center mt-6 bg-ResolutionBlue text-white text-sm font-semibold py-3 rounded-3xl"
                >
                  <span v-if="isLoading" class="flex justify-center">
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span v-else>Link Device</span>
                </button>
              </div>

              <div class="w-full md:w-5/12 space-y-4">
                <div class="w-[80%] h-24 md:h-64 rounded-lg border">
                  <img
                    src="../../../assets/images/Arm-TYPE-BPM-2.png"
                    class="w-full h-full object-contain"
                    alt=""
                  />
                </div>

                <div class="flex flex-row items-center space-x-3">
                  <p class="font-semibold text-OlsoGrey text-sm">
                    4G Blood Glucose Monitor
                  </p>
                  <span
                    class="text-DodgerBlue font-medium bg-blue-100 rounded-md px-2 py-1 text-xs"
                  >
                    Registered
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import DirectionalRightIcon from "@/components/icons/DirectionalRightIcon.vue";
import DirectionalLeftIcon from "@/components/icons/DirectionalLeftIcon.vue";
import CloseModalIcon from "@/components/icons/CloseModalIcon.vue";
import DeviceIcon from "@/components/icons/DeviceIcon.vue";
import AddNewDeviceIcon from "@/components/icons/AddNewDeviceIcon.vue";
import { push } from "notivue";

export default defineComponent({
  name: "PatientsList",

  components: {
    DirectionalRightIcon,
    DirectionalLeftIcon,
    CloseModalIcon,
    DeviceIcon,
    AddNewDeviceIcon,
  },

  setup() {
    const {
      // "device/addNewDevice": addNewDevice,
      "device/linkDevice": linkDevice,
      "user/fetchPatientsList": fetchPatientsList,
      "device/unlinkDevice": unlinkDevice,
    } = mapActions();
    // const { "user/fetchUserProfile": fetchUserProfile } = mapActions();

    const {
      "user/getPatientsList": getPatientsList,
      // "auth/getUserProfile": getUserProfile,
    } = mapGetters();

    const step = ref(1);
    const showModal = ref(false);
    const imei = ref("");
    const sn = ref("");
    // const category = ref("");
    const isLoading = ref(false);
    const selectedPatient = ref(null);
    const page = ref(getPatientsList?.value?.page || 1);
    // const page = ref(1);
    const itemsPerPage = ref(getPatientsList?.value?.per_page);
    const totalPages = ref(getPatientsList?.value?.total_pages);
    // const itemsPerPage = ref(10);
    const hypoglycaemic = ref(70);
    const normal = ref(100);
    const average_high = ref(125);
    const high = ref(126);

    // show selected patient infos
    const selectedPatientInfo = (patient) => {
      selectedPatient.value = patient;
      console.log("selected patient", selectedPatient.value);
    };

    // add new device
    const addPatientNewDevice = async () => {
      try {
        isLoading.value = true;
        const addDevice = await linkDevice({
          // imei: "862990060590808",
          // sn: "23F507028",
          imei: imei.value,
          sn: sn.value,
          patientId: selectedPatient.value.id,
        });
        // const addDevice = await addNewDevice(deviceDetails);
        if (addDevice) {
          console.log("addDevice 11", addDevice);
          push.success("Device Linked successfully");
          console.log("device addDevice:", addDevice);
        }
      } catch (error) {
        console.log("error ee", error);
        if (error) {
          push.error(error.response.data.message);
        }
        console.error("er adding device", error);
      } finally {
        isLoading.value = false;
      }
    };

    const getPaginatedPatientList = async (page) => {
      try {
        await fetchPatientsList(page);
        totalPages.value = getPatientsList?.value?.total_pages;
      } catch (error) {
        push.error("Error getting patient list");
      }
    };

    // switch to previous or next page
    const goToPage = async (pageNumber) => {
      if (pageNumber >= 1 && pageNumber <= totalPages.value) {
        page.value = pageNumber;
      }
      await getPaginatedPatientList(page.value);
    };

    const unlinkTheDevice = async (deviceIdToUnlink) => {
      try {
        const devices = selectedPatient?.value.medical_devices || [];

        for (const device of devices) {
          if (device.id === deviceIdToUnlink) {
            const res = await unlinkDevice(device.id);
            console.log(`Unlinking device ${device.id}`, res);

            if (res.message === "Device unlinked successfully") {
              push.success(res.message);
            }
            break;
          }
        }
      } catch (error) {
        push.error(error.response?.data?.message || "Error unlinking device");
      }
    };

    onMounted(async () => {
      getPaginatedPatientList(page.value);
      console.log("getPatientsList", getPatientsList.value);
    });

    return {
      step,
      showModal,
      imei,
      sn,
      isLoading,
      addPatientNewDevice,
      selectedPatient,
      selectedPatientInfo,
      getPatientsList,
      totalPages,
      goToPage,
      page,
      itemsPerPage,
      hypoglycaemic,
      average_high,
      high,
      normal,
      unlinkTheDevice,
    };
  },
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 65%;
  padding: 3rem;
  background: #fff;
  z-index: 999;
  transform: none;
}

.modal-overlay {
  content: "";
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: black;
  opacity: 0.6;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 9;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
</style>
