<template>
  <div class="space-y-5">
    <div>
      <label for="title" class="text-bold text-DarkJungleGreen">Title</label>
      <input
        class="font-extralight w-full border border-BattleshipGrey rounded-lg px-1.5 md:px-3 py-1 md:py-2 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-0"
        rows="0.5"
        type="text"
        name=""
        id="title"
        :value="post?.title"
        @input="updateTitle"
      />
    </div>
    <div>
      <label for="author" class="text-bold text-DarkJungleGreen">Author</label>
      <input
        class="font-extralight w-full border border-BattleshipGrey rounded-lg px-1.5 md:px-3 py-1 md:py-2 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-0"
        type="text"
        name=""
        id="author"
        :value="post?.author"
        @input="updateAuthor"
      />
    </div>
    <div class="flex justify-center items-center space-x-3">
      <button
        type="button"
        class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
        @click="closeEditBlogModal"
      >
        Cancel
      </button>
      <button
        class="bg-ResolutionBlue text-white w-[147px] p-1.5 md:p-3 border rounded-[44px] text-center text-base font-semibold"
        @click="updateBlogTitleAndAuthor"
      >
        <span v-if="isPending">
          <LoadingSpinner />
        </span>
        <span v-else> Update</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const queryClient = useQueryClient();

const props = defineProps(["post"]);

const closeEditBlogModal = inject("closeEditBlogModal");
const title = ref("");
const author = ref("");

const updateTitle = (event) => {
  title.value = event.target.value;
};

const updateAuthor = (event) => {
  author.value = event.target.value;
};

const {
  "blog/fetchBlogPosts": fetchBlogPosts,
  "blog/updateBlogPostTitleAndAuthor": updateBlogPostTitleAndAuthor,
} = mapActions();

const { isPending, mutate } = useMutation({
  mutationFn: (data) => updateBlogPostTitleAndAuthor(data),
  onError: (error) => {
    if (error) {
      push.error("Error updating post");
    }
  },
  onSuccess: (_) => {
    queryClient.invalidateQueries({ queryKey: ["blog-posts"] });
    push.success("Post updated successfully");
    title.value = "";
    author.value = "";
    closeEditBlogModal();
  },
});

const updateBlogTitleAndAuthor = () => {
  mutate({
    id: props.post.id,
    title: title.value ? title.value : updateTitle.value,
    author: author.value ? author.value : updateAuthor.value,
  });
};

useQuery({
  queryKey: ["blog-posts"],
  queryFn: () => fetchBlogPosts(),
});
</script>
