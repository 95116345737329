<template>
  <div
    class="container mx-auto overflow-y-auto scrollbar-hide h-[100vh] md:h-[95vh] py-16 w-full lg:w-[42%] lg:pl-20 lg:pr-2"
  >
    <div class="w-full relative">
      <div class="gap-8 mb-8 md:mb-12">
        <img
          src="../../assets/icons/turbomedic-logo__.svg"
          class="w-40 md:w-52"
          alt=""
        />
      </div>
      <div class="space-y-2 md:space-y-5 mb-3 md:mb-6">
        <p class="font-DuplicateSans font-semibold text-lg md:text-2xl">
          Create an account
        </p>
        <div class="flex justify-between">
          <p class="text-sm md:text-base text-OlsoGrey font-light">
            Get started today!
          </p>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-10">
          <div class="">
            <div class="space-x-1">
              <label for="first_name" class="text-sm text-BluishGrey"
                >First Name</label
              >
            </div>
            <input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:ring-2 focus:ring-ResolutionBlue"
              type="text"
              name="first_name"
              v-model="first_name"
            />
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'first_name cannot be empty.'"
            >
              First name is required
            </div>
          </div>
          <div class="">
            <label for="last_name" class="text-sm text-BluishGrey"
              >Last Name</label
            >
            <input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue font-bold"
              type="text"
              name="last_name"
              v-model="last_name"
            />
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'last_name cannot be empty.'"
            >
              Last name is required
            </div>
          </div>
          <div class="">
            <label for="username" class="text-sm text-BluishGrey"
              >User Name</label
            >
            <input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue font-bold"
              type="text"
              name="last_name"
              v-model="username"
            />
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'username cannot be empty.'"
            >
              User name is required
            </div>
          </div>
          <div class="">
            <label for="phone" class="text-sm text-BluishGrey">Phone</label>
            <input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue font-bold"
              type="number"
              name="phone"
              v-model="phone"
            />
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'phone cannot be empty.'"
            >
              Phone number is required
            </div>
          </div>
          <div class="">
            <label for="date_of_birth" class="text-sm text-BluishGrey"
              >Date of Birth</label
            >
            <!-- <input
                class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold"
                type="date"
                name="date_of_birth"
                v-model="date_of_birth"
                placeholder=""
              /> -->
            <input
              name="date_of_birth"
              v-model="date_of_birth"
              :type="inputType"
              @focus="inputType = 'date'"
              @focusout="inputType = 'text'"
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold"
            />
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'date_of_birth cannot be empty.'"
            >
              Date of birth is required
            </div>
          </div>
          <div class="">
            <label for="email" class="text-sm text-BluishGrey">Email</label>
            <input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue font-bold"
              type="email"
              name="email"
              v-model="email"
            />
            <div>
              <div
                class="text-ArtyClickRed text-xs"
                v-if="email_exist === true"
              >
                Email already exist
              </div>
              <div
                class="text-ArtyClickRed text-xs"
                v-else-if="invalid_email_error === 'Email is not valid.'"
              >
                Email is not valid
              </div>
            </div>
            <div
              class="text-ArtyClickRed text-xs"
              v-if="
                signupError ===
                'The email address is not valid. It must have exactly one @-sign.'
              "
            >
              Invalid email
            </div>
          </div>
          <div class="">
            <label for="gender" class="text-sm text-BluishGrey">Gender</label>
            <select
              name="gender"
              v-model="gender"
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold"
            >
              <option value="" disabled>Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'gender cannot be empty.'"
            >
              Gender is required
            </div>
          </div>

          <div class="">
            <div class="flex justify-between items-center pt-0.5">
              <label for="password" class="text-BluishGrey text-sm"
                >Password</label
              >
              <div
                class="cursor-pointer relative"
                :class="
                  !showPassword &&
                  'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
                "
                @click="showPassword = !showPassword"
              >
                <img src="../../assets/icons/password_eye.svg" alt="eye icon" />
              </div>
            </div>
            <input
              :type="showPassword ? 'text' : 'password'"
              name="password"
              id="password"
              class="border-b py-0.5 font-sans placeholder:font-urbanist text-sm md:text-[14px] items-center w-full border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue placeholder:text-OlsoGrey font-bold"
              v-model="password"
            />
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'password cannot be empty.'"
            >
              Password is required.
            </div>
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'Password is too short.'"
            >
              Password is too short
            </div>
          </div>
          <div>
            <div class="flex justify-between items-center pt-0.5">
              <label for="password" class="text-BluishGrey text-sm"
                >Confirm Password</label
              >
              <div
                class="cursor-pointer relative"
                :class="
                  !showPassword2 &&
                  'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
                "
                @click="showPassword2 = !showPassword2"
              >
                <img src="../../assets/icons/password_eye.svg" alt="eye icon" />
              </div>
            </div>
            <input
              :type="showPassword2 ? 'text' : 'password'"
              name="password_2"
              id="password_2"
              class="border-b py-0.5 font-sans placeholder:font-urbanist text-sm md:text-[14px] items-center w-full border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue placeholder:text-OlsoGrey font-bold"
              v-model="password_2"
            />
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'password_2 cannot be empty.'"
            >
              Confirmation password is required
            </div>
            <div
              class="text-ArtyClickRed text-xs"
              v-if="signupError === 'Confirmation password doesn\'t match.'"
            >
              Confirmation password doesn't match
            </div>
          </div>
        </div>
        <!-- <div class="flex items-center space-x-3 mt-6">
            <input
              type="checkbox"
              name=""
              id=""
              class="cursor-pointer p-3 border border-red-500 rounded-xl"
            />
            <p class="text-OlsoGrey text-sm">
              I agree to the company
              <router-link to="" class="text-DarkJungle hover:underline"
                >Term of Service</router-link
              >
              and
              <router-link to="" class="text-DarkJungle hover:underline"
                >Privacy Policy</router-link
              >
            </p>
          </div> -->
        <div class="w-full flex flex-col items-center justify-center space-y-3">
          <button
            @click="doesEmailExist"
            class="custom-button font-semibold bg-ResolutionBlue text-sm md:text-[14px] rounded-3xl text-white mt-8 py-3 duration-500 w-full"
          >
            <span v-if="isLoading">
              <LoadingSpinner />
            </span>
            <span v-else>Submit</span>
          </button>
          <div class="w-full">
            <div
              class="mt-2 md:mt-2 text-[10px] md:text-xs flex items-center justify-center space-x-1"
            >
              <p class="text-MountainMist">Already have an account?</p>
              <router-link
                to="/auth/sign-in"
                class="text-ResolutionBlue font-semibold hover:underline"
                >Sign in</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopUp v-if="showPopup" />
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import PopUp from "./PopUp.vue";

export default defineComponent({
  name: "SingUp",
  components: {
    LoadingSpinner,
    PopUp,
  },

  setup() {
    const { "auth/signup": signup } = mapActions();
    const { "auth/isUserExist": isUserExist } = mapActions();
    const router = useRouter();

    // const preview = ref(null);
    // const image = ref(null);
    const email = ref("");
    const password = ref("");
    const password_2 = ref("");
    const first_name = ref("");
    const last_name = ref("");
    const username = ref("");
    const phone = ref("");
    const date_of_birth = ref("");
    const gender = ref("");
    const isLoading = ref(false);
    const showPopup = ref(false);
    const signupError = ref(null);
    const email_exist = ref(null);
    const invalid_email_error = ref(null);
    const showPassword = ref(false);
    const showPassword2 = ref(false);

    const date = ref("");
    const inputType = ref("text");

    // const handleImageUpload = (event) => {
    //   image.value = event.target.files[0];
    // };

    // chech if user email exist
    const doesEmailExist = async () => {
      const userEmail = email.value;
      try {
        isLoading.value = true;
        const verifyUserEmail = await isUserExist(userEmail);
        console.log("verifyUserEmail", verifyUserEmail);
        if (verifyUserEmail.email_exists === true) {
          email_exist.value = verifyUserEmail.email_exists;
        } else if (verifyUserEmail.email_exists === false) {
          signUpUser();
        }
      } catch (error) {
        console.error("11", error);
        // console.error("11", error.response.data.message);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          invalid_email_error.value = error.response.data.message;
        console.log("invalid_email_error 9m", invalid_email_error);
      } finally {
        isLoading.value = false;
      }
    };

    // sign up user
    const signUpUser = async () => {
      const signupCredentials = {
        email: email.value,
        password: password.value,
        password_2: password_2.value,
        first_name: first_name.value,
        last_name: last_name.value,
        username: username.value,
        phone: phone.value,
        date_of_birth: date_of_birth.value,
        gender: gender.value,
      };
      try {
        isLoading.value = true;
        const userData = await signup(signupCredentials);
        console.log("userData in 1 com", userData);
        console.log("signupCredentials", signupCredentials);
        if (
          userData
          // userData.registration_success === true
        ) {
          console.log("userData in 2 com", userData);
          //  Show the popup
          showPopup.value = true;
          setTimeout(() => {
            showPopup.value = false;
          }, 4000);

          // Redirect to email verification page
          router.push({
            path: "/auth/verify-email",
            query: { email: signupCredentials.email },
          });

          // Clear the input fields
          email.value = "";
          password.value = "";
          password_2.value = "";
          first_name.value = "";
          last_name.value = "";
          username.value = "";
          phone.value = "";
          date_of_birth.value = "";
          gender.value = "";
        }
      } catch (error) {
        push.error("Error while signing up");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          signupError.value = error.response.data.message;
          console.log("signupError 11", signupError.value);
        }
        console.error("sign up error 33", error);
      } finally {
        isLoading.value = false;
      }
    };

    // const maxDate = ref(new Date().toISOString().split("T")[0]);

    // const previewImage = (event) => {
    //   var input = event.target;
    //   if (input.files) {
    //     var reader = new FileReader();
    //     reader.onload = (e) => {
    //       preview.value = e.target.result;
    //     };
    //     image.value = input.files[0];
    //     reader.readAsDataURL(input.files[0]);
    //   }
    // };

    return {
      date,
      inputType,
      // preview,
      // image,
      email,
      password,
      password_2,
      first_name,
      last_name,
      username,
      phone,
      date_of_birth,
      gender,
      isLoading,
      showPopup,
      signUpUser,
      signupError,
      showPassword,
      showPassword2,
      doesEmailExist,
      email_exist,
      invalid_email_error,
      // previewImage,
    };
  },
});
</script>

<style scoped>
.custom-button:disabled {
  background-color: #808ebf;
}

.no-placeholder::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
