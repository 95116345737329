<template>
  <ModalWrapper>
    <div
      class="max-w-[724px] w-full p-6 md:p-12 rounded-[48px] bg-white relative"
    >
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          Edit Blood Glucose
        </h3>
        <div class="cursor-pointer" @click="closeEditManualTestModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <!-- Test Input Section -->
      <div
        class="max-w-md w-full mx-auto flex flex-col items-center gap-[30px]"
      >
        <div
          class="max-w-[414px] w-full h-[186px] border-2 border-ClearBlue flex justify-center items-center rounded-xl"
        >
          <div class="w-[134px] flex gap-3 justify-center items-center">
            <input
              v-model="testValue"
              type="text"
              class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-full bg-transparent border-none outline-none"
              :placeholder="editingData?.glucose_level || '__'"
            />
            <div class="relative">
              <button
                @click="toggleDropdown"
                class="flex items-center justify-center gap-1 py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
                <img
                  src="@/assets/icons/white-down-arrow.svg"
                  alt="unit dropdown"
                  class="ml-1 w-4 h-4"
                  :class="{ 'rotate-180': isDropdownOpen }"
                />
              </button>
              <!-- Custom dropdown menu -->
              <div
                v-if="isDropdownOpen"
                class="absolute top-full left-0 mt-1 w-full bg-white border border-BlueChalk rounded-lg shadow-lg z-50"
              >
                <button
                  v-for="option in unitOptions"
                  :key="option"
                  @click="selectUnit(option)"
                  class="block w-full text-left px-4 py-2 text-sm text-ResolutionBlue hover:bg-BlueChalk"
                  :class="{ 'bg-BlueChalk': units === option }"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Before/After Meal Selection -->
        <div
          class="flex justify-between items-center space-x-2.5 py-1 px-2 rounded-full bg-BlueChalk"
        >
          <button
            @click="setActiveTab('beforeMeal')"
            :class="[
              activeTab === 'beforeMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full cursor-pointer',
            ]"
          >
            Before meal
          </button>
          <button
            @click="setActiveTab('afterMeal')"
            :class="[
              activeTab === 'afterMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full cursor-pointer',
            ]"
          >
            After meal
          </button>
        </div>

        <!-- Date and Time Selection -->
        <div class="w-full flex items-center gap-3">
          <div class="w-full cursor-pointer relative" @click="openDatePicker">
            <input
              type="date"
              ref="datePicker"
              v-model="selectedDate"
              :max="maxDate()"
              class="w-full absolute inset-0 opacity-0 pointer-events-auto"
            />
            <input
              type="text"
              :value="formattedDate"
              placeholder="Choose date"
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey outline-none cursor-pointer"
            />
            <img
              src="@/assets/icons/calendar-gray-icon.svg"
              alt="calendar icon"
              class="ml-2 w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
            />
          </div>

          <div class="w-full cursor-pointer relative" @click="openTimePicker">
            <input
              type="text"
              :value="formattedTime"
              placeholder="Choose time"
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey outline-none cursor-pointer"
            />
            <img
              src="@/assets/icons/clock.svg"
              alt="clock icon"
              class="ml-2 w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
            />
            <input
              type="time"
              ref="timePicker"
              v-model="selectedTime"
              class="w-full absolute inset-0 opacity-0 pointer-events-auto"
            />
          </div>
        </div>

        <!-- Submit and Cancel Section -->
        <div class="flex justify-center items-center gap-4 mt-8">
          <button
            @click="closeEditManualTestModal"
            class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
          >
            Cancel
          </button>
          <button
            @click="handleSubmit"
            :disabled="isLoading || !isFormValid"
            :class="[
              'px-[52px] py-3 text-white rounded-full text-sm font-semibold',
              isLoading || !isFormValid
                ? 'bg-[#E0E0E0] cursor-not-allowed'
                : 'bg-ResolutionBlue cursor-pointer',
            ]"
          >
            <span v-if="isLoading">
              <LoadingSpinner />
            </span>
            <span v-else> Save </span>
          </button>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref, computed, inject, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import { maxDate } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import ModalWrapper from "./ModalWrapper.vue";

const { "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords } =
  mapActions();

// Props received for editing
const props = defineProps({
  patientName: String,
  patientId: String,
  editingData: Object, // Data passed for editing
});

const store = useStore();

const testValue = ref(props.editingData?.glucose_level || "");
const selectedDate = ref(""); // Raw date value
const selectedTime = ref(""); // Raw time value
const formattedDate = ref(""); // Formatted date for display
const formattedTime = ref(""); // Formatted time for display
const activeTab = ref("beforeMeal");
const units = ref("mg/dl");
const unitOptions = ["mg/dl", "mmol/l"];
const isDropdownOpen = ref(false);
const isLoading = ref(false);
// References for the hidden input elements
const datePicker = ref(null);
const timePicker = ref(null);

const closeEditManualTestModal = inject("closeEditManualTestModal");

// Function to switch between "beforeMeal" and "afterMeal"
const setActiveTab = (tab) => {
  activeTab.value = tab;
};

// Functions to populate the form with existing data
const populateFormWithEditingData = () => {
  if (props.editingData) {
    testValue.value = props.editingData.glucose_level;
    activeTab.value = props.editingData.before_meal
      ? "beforeMeal"
      : "afterMeal";
    units.value = props.editingData.units || "mg/dl";

    if (props.editingData.check_time) {
      const dateTime = new Date(props.editingData.check_time);
      selectedDate.value = dateTime.toISOString().split("T")[0];
      selectedTime.value = dateTime.toTimeString().slice(0, 5);
    }

    handleDateChange();
    handleTimeChange();
  }
};

watch(
  () => props.editingData,
  () => {
    populateFormWithEditingData();
  }
);

// Form validation
const isFormValid = computed(() => {
  return testValue.value && formattedDate.value && formattedTime.value;
});

// Handle submit action
const handleSubmit = async () => {
  if (!isFormValid.value) {
    return;
  }

  isLoading.value = true;

  const payload = {
    Check_Time: `${formattedDate.value} ${formattedTime.value}`,
    bloodSugar: testValue.value,
    beforeOrAfterMeals: activeTab.value === "beforeMeal" ? 1 : 0,
    units: units.value,
  };

  try {
    await store.dispatch("test/editManualTest", {
      glucoseLevelId: props.editingData.glucose_level_id,
      payload,
    });
    push.success("Test result edited successfully!");
    closeEditManualTestModal();
    fetchBloodGlucoseRecords({
      test_type: "glucose",
    });
  } catch (error) {
    push.error(error.response?.data?.message || "Error editing the form.");
  } finally {
    isLoading.value = false;
  }
};

// Function to toggle dropdown
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const openDatePicker = () => {
  datePicker.value.click();
};

// Method to open hidden time picker
const openTimePicker = () => {
  timePicker.value.click();
};

// Function to select a unit
const selectUnit = (newUnit) => {
  units.value = newUnit;
  isDropdownOpen.value = false;
};

// Handle date and time changes
const handleDateChange = () => {
  if (selectedDate.value) {
    const date = new Date(selectedDate.value);
    formattedDate.value = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }
};

const handleTimeChange = () => {
  if (selectedTime.value) {
    const time = new Date(`1970-01-01T${selectedTime.value}:00`);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    formattedTime.value = `${hours}:${minutes}:${seconds}`;
  }
};

// Watch for changes in selectedDate and selectedTime
watch(selectedDate, handleDateChange);
watch(selectedTime, handleTimeChange);
</script>

<style scoped>
.input-with-dashes::placeholder {
  text-align: center;
  color: #011d7e;
}
</style>
