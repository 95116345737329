<template>
  <ModalWrapper>
    <div
      class="bg-white w-full max-w-[724px] p-6 md:p-12 rounded-[48px] relative"
    >
      <div v-if="!showForm && !showResult">
        <div class="flex justify-between items-center mb-8">
          <h3 class="text-ResolutionBlue text-xl font-bold">Blood Glucose</h3>
          <div class="cursor-pointer" @click="closeTestStepsModal">
            <span
              class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 befoafterre:top-0 after:-rotate-45"
            ></span>
          </div>
        </div>

        <div class="flex flex-col justify-center items-center gap-8">
          <div>
            <img
              src="../../../../../assets/icons/medical_device_image_steps.svg"
              alt="medical device"
            />
          </div>

          <div>
            <h2 class="text-BalticSea text-base text-center font-bold">
              Please follow these steps:
            </h2>
            <ul>
              <ol
                class="text-Gravel text-sm list-decimal flex flex-col justify-center items-center"
              >
                <li>Insert a test strip into the device</li>
                <li>
                  Place a sample of the blood on the sensitive tip of the test
                  strip
                </li>
                <li>Wait till countdown is done</li>
              </ol>
            </ul>
          </div>

          <div
            class="flex justify-center items-center gap-2 flex-wrap relative"
          >
            <button
              class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
              @click="closeTestStepsModal"
            >
              Cancel
            </button>
            <button
              class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px]"
              @click="showForm = true"
            >
              Done
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="showResult"
        class="bg-white w-full max-w-[724px] p-6 md:p-12 rounded-[48px] relative"
      >
        <div class="flex justify-between items-center mb-7">
          <h3 class="text-ResolutionBlue text-xl font-bold">Blood Glucose</h3>
          <div class="cursor-pointer" @click="closeTestStepsModal">
            <span
              class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 befoafterre:top-0 after:-rotate-45"
            ></span>
          </div>
        </div>

        <div class="flex flex-col justify-center items-center pt-5 mb-7">
          <h1 class="text-OrangePeel text-4xl font-bold text-center mb-2">
            <!-- {{
                result?.glucose_records[result?.glucose_records.length - 1]
                  ?.glucose_level
              }}mg/dL -->
            {{ result?.glucose_records[0]?.glucose_level }}mg/dL
          </h1>
          <span
            class="bg-TitanWhite text-UltramarineBlue text-xs font-semibold py-1 px-2 rounded-md"
            >{{
              result?.glucose_records[result?.glucose_records.length - 1]
                ?.before_meal
                ? "Before meal"
                : "After meal"
            }}</span
          >
        </div>

        <div class="mb-7">
          <textarea
            name="notes"
            placeholder="Add your notes here"
            class="w-full h-[106px] resize-none py-4 px-[14px] border border-Platinum outline-Platinum focus:border-Platinum rounded-lg placeholder:text-MistBlue placeholder:text-sm"
            v-model="notes"
          ></textarea>
        </div>

        <div class="flex justify-center items-center gap-2 flex-wrap relative">
          <button
            class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
            @click="closeTestStepsModal"
          >
            Cancel
          </button>
          <button
            class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px]"
            :class="notes.length > 0 ? ' opacity-100' : 'opacity-50'"
            @click="updateNotes"
          >
            Submit
          </button>
        </div>
      </div>

      <form
        v-if="showForm"
        @submit.prevent="submitResult"
        class="flex flex-col gap-y-8"
      >
        <div class="flex justify-between items-center">
          <h3 class="text-ResolutionBlue text-xl font-bold">
            Fill in test result
          </h3>
          <div class="cursor-pointer" @click="closeTestStepsModal">
            <span
              class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 befoafterre:top-0 after:-rotate-45"
            ></span>
          </div>
        </div>

        <div>
          <label for="bloodSugarLevel" class="text-BluishGrey text-sm"
            >Blood Sugar Level</label
          >
          <div>
            <input
              type="text"
              id="bloodSugarLevel"
              class="w-full outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold focus:border-ResolutionBlue focus:ring-2"
              v-model="bloodSugar"
            />
          </div>
        </div>

        <div>
          <label for="beforeOrAfterMeals" class="text-BluishGrey text-sm"
            >Before or after meals</label
          >
          <div>
            <input
              type="text"
              id="beforeOrAfterMeals"
              class="w-full outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold focus:border-ResolutionBlue focus:ring-2"
              v-model="beforeOrAfterMeals"
            />
          </div>
        </div>

        <div class="flex justify-center items-center gap-2 flex-wrap relative">
          <button
            type="button"
            class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
            @click="showForm = false"
          >
            Back
          </button>
          <button
            type="submit"
            class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px]"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </ModalWrapper>
</template>

<script>
import { inject, ref } from "vue";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { push } from "notivue";
import ModalWrapper from "./ModalWrapper.vue";

export default {
  name: "TestStepsModal",
  components: {
    ModalWrapper,
  },
  setup() {
    const queryClient = useQueryClient();

    const closeTestStepsModal = inject("closeTestStepsModal");
    const openTestSuccessfulModal = inject("openTestSuccessfulModal");
    const currentPatientAndDevice = inject("currentPatientAndDevice");

    const showForm = ref(false);
    const showResult = ref(false);
    const bloodSugar = ref("");
    const beforeOrAfterMeals = ref("");
    const result = ref(null);
    const notes = ref("");

    const {
      "test/submitTestResult": submitTestResult,
      "user/getPatientById": getPatientById,
      "test/updateTestNotes": updateTestNotes,
      "device/unlinkDevice": unlinkDevice,
      "user/sendProgressReport": sendProgressReport,
      "user/fetchPatientProfile": fetchPatientProfile,
    } = mapActions();

    const { "user/getPatientProfile": getPatientProfile } = mapGetters();

    const fetchPatient = async () => {
      try {
        const res = await getPatientById(
          currentPatientAndDevice.value.patient.id
        );
        result.value = res;
        showResult.value = true;
        showForm.value = false;
      } catch (error) {
        console.error("Error getting patient by ID", error);
      }
    };

    const submitResult = async () => {
      if (bloodSugar.value && beforeOrAfterMeals.value) {
        try {
          await submitTestResult({
            bloodSugar: bloodSugar.value,
            beforeOrAfterMeals: beforeOrAfterMeals.value,
          });
          bloodSugar.value = "";
          beforeOrAfterMeals.value = "";
          push.success("Test submitted successfully");

          // Fetch the patient profile to get the patient ID
          const patientProfile = await fetchPatientProfile();

          // Ensure patientProfile is not null and has the patient ID
          if (patientProfile && patientProfile.person_id) {
            // Call sendProgressReport with the patient ID
            await sendProgressReport(patientProfile.id);
          } else {
            throw new Error("Patient profile not found or missing ID.");
          }
          // Close the modal after successful submission
          closeTestStepsModal();
        } catch (error) {
          push.error("Error submitting result");
        }
      } else {
        push.info("Fill in all fields");
      }
    };

    const { mutate } = useMutation({
      mutationFn: (data) => unlinkDevice(data),
      onError: (error) => {
        if (error) {
          push.error(error.response.data.message);
        }
      },
      onSuccess: (_) => {
        queryClient.invalidateQueries({ queryKey: ["devices"] });

        push.success("Deviced unlinked successfully!");
      },
    });

    const unlinkTheDevice = () => {
      mutate(currentPatientAndDevice.value.device.id);
    };

    const updateNotes = async () => {
      if (notes.value.length > 0) {
        try {
          await updateTestNotes({
            noteId:
              result.value?.glucose_records[
                result.value?.glucose_records.length - 1
              ].id,
            note: notes.value,
          });
          closeTestStepsModal();
          openTestSuccessfulModal();
          unlinkTheDevice();
        } catch (error) {
          push.error(error.response.data.message);
        }
      }
    };

    return {
      closeTestStepsModal,
      openTestSuccessfulModal,
      showResult,
      showForm,
      bloodSugar,
      beforeOrAfterMeals,
      result,
      notes,
      submitResult,
      updateNotes,
    };
  },
};
</script>
