<template>
  <div
    class="fixed top-0 left-0 z-[60] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[600px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex items-center justify-between mb-12">
          <div class="space-y-2.5">
            <p
              class="text-sm md:text-xl font-semibold cursor-pointer text-Charcoal"
            >
              Enter your verification codes to continue
            </p>
          </div>
          <div class="self-start cursor-pointer" @click="closeModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-8 md:space-y-12">
          <div class="flex flex-col justify-center space-x-4">
            <div class="flex flex-col items-center justify-center space-y-3">
              <div>
                <label for="email_otp" class="text-MistBlue text-xs font-light"
                  >Enter the OTP code sent to your email</label
                >
                <input
                  class="text-center rounded-lg w-full text-3xl py-1 px-4 border border-BlueChalk text-DarkJungle focus:outline-none focus:border font-bold focus:border-ResolutionBlue focus:ring-2"
                  type="text"
                  name="text"
                  v-model="email_otp"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-center space-x-4">
            <button
              type="button"
              @click="closeModal"
              class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
            >
              Cancel
            </button>
            <button
              @click="loginWithOtp"
              class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px] w-44"
            >
              <span v-if="isLoading" class="pl-3">
                <LoadingSpinner />
              </span>
              <span v-else>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { ROLES } from "@/utils/constants";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";

const router = useRouter();

const { "twoFactorAuthentication/loginWithEmailOtp": loginWithEmailOtp } =
  mapActions();

const closeModal = inject("closeModal");

const props = defineProps(["email", "password"]);

const isLoading = ref(false);
const email_otp = ref("");

const loginWithOtp = async () => {
  try {
    isLoading.value = true;
    const userData = await loginWithEmailOtp({
      email: props.email,
      password: props.password,
      email_otp: email_otp.value,
    });
    if (userData && userData[0].login === true) {
      closeModal();
      console.log("log-in with OTP userData 11", userData);
      const { roles } = userData[0].user;
      if (roles[0] === ROLES.ADMIN) {
        router.push("/admin/dashboard");
      } else if (roles[0] === ROLES.PATIENT) {
        router.push("/patient/dashboard");
      }
    }
  } catch (error) {
    console.log("log-in with OTP error", error.response.data.message);
    push.error(error.response.data.message);
  } finally {
    isLoading.value = false;
  }
};
</script>

<script>
export default {
  name: "OtpAndRecorveryCodeModal",
};
</script>
